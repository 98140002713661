import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { theme } from "../theme";
import { useSubmitFeedbackMutation } from "./submit-feedback.graphql";

type FeedbackFormProperties = {
  onClose: () => void;
  careUnitId?: string;
  isRatingAllowed?: boolean;
  headingText: {
    title: string;
    description: string;
  };
  success: {
    descriptionLineOne: string;
    descriptionLineTwo?: string;
  };
};

interface FormValues {
  canContactUser?: boolean;
  rating?: number;
  feedback: string;
}

function FeedbackForm(properties: FeedbackFormProperties) {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onChange",
  });

  const [
    submitFeedbackMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useSubmitFeedbackMutation();

  const onSubmit = async ({ rating, feedback, canContactUser }: FormValues) => {
    await submitFeedbackMutation({
      variables: {
        careUnitId: properties.careUnitId,
        rating,
        feedback,
        canContactUser,
      },
    });
  };

  if (mutationError) {
    throw new Error(t`Something went wrong while submitting feedback.`);
  }

  if (mutationData && mutationData.submitFeedback?.success === true) {
    return (
      <Box
        sx={{
          padding: { sm: 3, xs: 2 },
          textAlign: "center",
          minHeight: "25rem",
        }}
      >
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          marginBottom="3rem"
        >
          <Trans>Thank you for your feedback!</Trans>
        </Typography>
        <Typography
          variant="h4"
          align="center"
          margin="dense"
          marginBottom="3rem"
          marginX="2rem"
          color="info"
          sx={{
            opacity: 0.6,
          }}
          gutterBottom
        >
          {properties.success.descriptionLineOne}
        </Typography>
        {properties.success.descriptionLineTwo && (
          <Typography
            variant="h4"
            align="center"
            margin="dense"
            color="info"
            marginBottom="3rem"
            marginX="2rem"
            sx={{
              opacity: 0.6,
            }}
            gutterBottom
          >
            {properties.success.descriptionLineTwo}
          </Typography>
        )}
        <Button variant="contained" fullWidth onClick={properties.onClose}>
          <Trans>Close</Trans>
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: { sm: 3, xs: 2 }, textAlign: "center" }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Typography variant="h2" align="center" gutterBottom>
          {properties.headingText.title}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          margin="dense"
          marginX="1.5rem"
          color="info"
          sx={{ opacity: 0.5 }}
          gutterBottom
        >
          {properties.headingText.description}
        </Typography>
        {properties.isRatingAllowed && (
          <>
            <Controller
              name="rating"
              control={control}
              defaultValue={undefined}
              rules={{
                required: t`Rating is required.`,
              }}
              render={({ field }) => (
                <Rating
                  {...field}
                  disabled={mutationLoading}
                  sx={{
                    color: theme.palette.secondary.main,
                    fontSize: "3.5rem",
                    marginTop: "0.5rem",
                  }}
                  // eslint-disable-next-line max-params
                  onChange={(_, value) => field.onChange(value)}
                />
              )}
            />
            {errors.rating && (
              <Typography variant="subtitle2" align="center" color="info">
                <Trans>Please select a rating.</Trans>
              </Typography>
            )}
          </>
        )}

        <Controller
          name="feedback"
          control={control}
          defaultValue={""}
          rules={{
            required: t`Feedback is required.`,
          }}
          render={({ field }) => (
            <TextField
              disabled={mutationLoading}
              {...field}
              size="medium"
              error={Boolean(errors.feedback)}
              helperText={errors.feedback?.message}
              onChange={(event) => field.onChange(event.target.value)}
              multiline
              fullWidth
              rows={4}
            />
          )}
        />
        <FormControlLabel
          control={
            <Controller
              name="canContactUser"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  disabled={mutationLoading}
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                  {...field}
                  onChange={(event) => field.onChange(event.target.checked)}
                />
              )}
            />
          }
          label={
            <Typography sx={{ lineHeight: 1.3, marginBottom: 1 }} variant="h6">
              <Trans>I agree to be contacted for follow-up questions</Trans>
            </Typography>
          }
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={mutationLoading || !isValid}
          endIcon={mutationLoading ? <CircularProgress /> : <ArrowForward />}
        >
          <Trans>Send</Trans>
        </Button>
      </form>
    </Box>
  );
}

export { FeedbackForm };
