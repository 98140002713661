import { Trans } from "@lingui/macro";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { DialogBottom } from "../../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../icons/arrow-right.svg";
import { ReactComponent as ClockIcon } from "../../icons/clock.svg";
import { useBackgroundLocation } from "../../router/background-location-context";
import { colors, theme } from "../../theme";

function AskWaitlistDialog() {
  const backgroundLocation = useBackgroundLocation();
  const navigate = useNavigate();
  const { appointmentId } = useParams();

  invariant(appointmentId);

  const [isDialogOpen, setIsDialogOpen] = useState(true);

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  return (
    <DialogBottom
      open={isDialogOpen}
      onClose={handleDialogClose}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          onClick={() => navigate(-1)}
          underline="none"
          color={colors.blue}
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          padding: {
            xs: 2,
            sm: 3,
          },
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          gap: "24px",
        }}
      >
        <Avatar
          sx={{
            width: "30px",
            height: "30px",

            backgroundColor: colors.zymegoBluePowder,
          }}
        >
          <ClockIcon stroke={colors.blue} fill={colors.blue} />
        </Avatar>

        <Typography variant="h6" align="center">
          <Trans>Get an earlier appointment</Trans>
        </Typography>
      </Box>
      <Box
        sx={{
          padding: {
            xs: 2,
            sm: 3,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" align="center">
          <Trans>Would you like offers of earlier appointments?</Trans>
        </Typography>
      </Box>
      <Box
        sx={{
          padding: {
            xs: 2,
            sm: 3,
          },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          endIcon={
            theme.direction === "ltr" ? (
              <ArrowRight style={{ fill: "currentColor" }} />
            ) : (
              <ArrowLeft style={{ fill: "currentcolor" }} />
            )
          }
          component={Link}
          to="../join"
          state={{ backgroundLocation }}
        >
          <Trans>Yes, please</Trans>
        </Button>
      </Box>
      <Box>
        <Button
          fullWidth
          size="large"
          variant="dialog"
          onClick={handleDialogClose}
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              padding: {
                xs: 0.5,
                sm: 1,
              },
              gap: "24px",
            }}
          >
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                color: colors.red,
                backgroundColor: colors.zymegoPowder,
              }}
            >
              <CloseIcon />
            </Avatar>
            <Trans>No, thank you</Trans>
          </Box>
        </Button>
      </Box>
    </DialogBottom>
  );
}

export { AskWaitlistDialog };
