import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import { ErrorComponent } from "../error";
import { useGetProfileSelectorDataQuery } from "../guardianship/get-profile-selector-data.graphql";
import { useSwitchSessionMutation } from "../guardianship/switch-session.graphql";
import { Loading } from "../loading";
import { getConfig } from "../runtime-config";
import { splitByComma } from "../utils";

const { ALLOW_GUARDIANSHIP } = getConfig();

function WelcomeNewAppointment({ careUnitNames }: { careUnitNames: string[] }) {
  const location = useLocation();
  const [searchParameters] = useSearchParams();
  const caregiverIds = splitByComma(searchParameters.get("caregiverId"));
  const bookingTypeIds = splitByComma(searchParameters.get("bookingTypeId"));

  if (caregiverIds.length > 0) {
    searchParameters.set("caregiverProvided", "true");
  }
  if (bookingTypeIds.length > 0) {
    searchParameters.set("bookingTypeProvided", "true");
  }

  const [
    switchSession,
    { error: switchSessionError, loading: switchSessionLoading },
  ] = useSwitchSessionMutation();

  const {
    data: { guardian, patient } = {},
    loading: profileSelectorLoading,
    error: profileSelectorError,
  } = useGetProfileSelectorDataQuery();

  useEffect(() => {
    if (guardian && patient?.id !== guardian?.id) {
      switchSession({
        variables: {
          patientId: guardian.id ?? patient?.id ?? "",
        },
      });
    }
  }, [patient, guardian, switchSession]);

  if (profileSelectorLoading || switchSessionLoading) {
    return (
      <Box>
        <Loading text={t`Loading...`} logo={false} />
      </Box>
    );
  }
  if (profileSelectorError || switchSessionError) {
    return <ErrorComponent component="new-booking-error" />;
  }
  return (
    <>
      {careUnitNames.map((careUnitName) => (
        <div key={careUnitName}>
          <Typography fontSize="25px" fontWeight={500} textAlign="center">
            {careUnitName}
            <br />
          </Typography>
        </div>
      ))}
      <Box
        textAlign="center"
        sx={{
          padding: {
            xs: 4,
            sm: 5,
          },
          display: "inline-grid",
          gap: "10px",
        }}
      >
        {ALLOW_GUARDIANSHIP ? (
          <Typography fontSize="15px" fontWeight={700}>
            <Trans>
              Select if you want to book an appointment for yourself or for a
              child
            </Trans>
          </Typography>
        ) : (
          ""
        )}

        <Button
          variant="contained"
          fullWidth
          endIcon={<ArrowForward />}
          component={Link}
          to={{
            pathname: "type-of-appointment",
            search: `${searchParameters}`,
          }}
        >
          <Trans>Book for myself</Trans>
        </Button>

        {ALLOW_GUARDIANSHIP ? (
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            endIcon={<ArrowForward />}
            component={Link}
            state={{ backgroundLocation: location }}
            to={{ pathname: "add-guardianship", search: `${searchParameters}` }}
          >
            <Trans>Book for a child</Trans>
          </Button>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
export { WelcomeNewAppointment };
