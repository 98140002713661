import { GeographyConfig, getGeographyConfig } from "./geographies";
import { getConfig } from "./runtime-config";

type Environment = "development" | "staging" | "production";

type FeatureFlagName =
  | "consentForConfirmationsAndReminders"
  | "lateAppointmentChanges";

// eslint-disable-next-line max-params
export function isFeatureEnabled(
  name: FeatureFlagName,
  options: { geographyConfig?: GeographyConfig } = {},
): boolean {
  const { geographyConfig = getGeographyConfig() } = options;
  const { ENVIRONMENT } = getConfig();

  const featureFlags: Record<FeatureFlagName, Record<Environment, boolean>> = {
    /**
     * @see https://app.clickup.com/t/8694f41zg
     */
    consentForConfirmationsAndReminders: {
      development: false,
      staging: false,
      production: false,
    },
    lateAppointmentChanges: {
      development: geographyConfig.enabledFeatures.lateAppointmentChanges,
      staging: geographyConfig.enabledFeatures.lateAppointmentChanges,
      production: geographyConfig.enabledFeatures.lateAppointmentChanges,
    },
  };

  return featureFlags[name][ENVIRONMENT];
}
