import { t, Trans } from "@lingui/macro";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { ReactComponent as TwoArrows } from "../icons/two-arrows.svg";
import { InfoBox } from "../info-box/info-box";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import {
  hasAppointmentExceededRescheduleLimit,
  isAppointmentWaitlistable,
} from "../utils";
import {
  GetAppointmentQuery,
  useGetAppointmentQuery,
} from "./get-appointment.graphql";

function getCancelLink({
  appointment,
  hasNotExceededRescheduleLimit,
}: {
  appointment: GetAppointmentQuery["appointment"];
  hasNotExceededRescheduleLimit: boolean;
}) {
  if (hasNotExceededRescheduleLimit && appointment?.canReschedule) {
    return "../cancel";
  }

  if (appointment?.bookingType?.cancellationReasonRequired) {
    return "../cancel/reason";
  }

  return "../cancel/confirm";
}

const onHelpClick = false;

function ManageAppointment() {
  const backgroundLocation = useBackgroundLocation();
  const navigate = useNavigate();
  const { appointmentId } = useParams();

  invariant(appointmentId);

  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const {
    data: { appointment } = {},
    loading,
    error,
  } = useGetAppointmentQuery({
    variables: {
      id: appointmentId,
    },
  });

  if (loading || !appointment) {
    return null;
  }

  if (error) {
    throw new Error(t`Something went wrong while fetching your appointment.`);
  }

  const hasNotExceededRescheduleLimit =
    !hasAppointmentExceededRescheduleLimit(appointment);

  const isWaitlistable = isAppointmentWaitlistable(appointment);

  const canOnlyCancel = !appointment?.canReschedule && appointment?.canCancel;
  const canOnlyReschedule =
    appointment?.canReschedule && !appointment?.canCancel;

  return (
    <DialogBottom
      open={isDialogOpen}
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          onClick={() => setIsDialogOpen(false)}
          underline="none"
          color={colors.blue}
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      {isWaitlistable ? (
        <Box
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <Typography variant="h4" gutterBottom>
              <Trans>Would you like offers of earlier appointments?</Trans>
            </Typography>
            {onHelpClick ? (
              <Avatar
                sx={{
                  width: "34px",
                  height: "34px",
                  backgroundColor: "#ffffff",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  color: "#000000",
                  fontWeight: 700,
                  fontSize: "22px",
                  display: "inline-flex",
                }}
                onClick={() => {
                  // onHelpClick();
                }}
              >
                ?
              </Avatar>
            ) : null}
          </Box>
          <Box paddingX={4} paddingBottom={1}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              component={Link}
              to="../waitlist/join"
              state={{ backgroundLocation }}
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight style={{ fill: "currentColor" }} />
                ) : (
                  <ArrowLeft style={{ fill: "currentcolor" }} />
                )
              }
            >
              <Trans>Yes, please</Trans>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            <Trans>Manage booking</Trans>
          </Typography>
        </Box>
      )}
      {appointment?.canReschedule && (
        <Box
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          {" "}
          {hasNotExceededRescheduleLimit ? (
            <Button
              data-cy="manage-appointments-reschedule-button"
              fullWidth
              size="large"
              variant="dialog"
              component={Link}
              to="../reschedule"
              disabled={
                (
                  appointment?.rescheduleAppointmentRequestsByAppointmentId ??
                  []
                ).length > 0
              }
            >
              <Box
                sx={{
                  margin: "auto",
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "24px",
                  justifyContent: "flex-start",
                }}
              >
                <Avatar
                  sx={{
                    width: "30px",
                    height: "30px",

                    color: colors.zymegoWarmGreen,
                    backgroundColor: colors.zymegoSpearMint,
                  }}
                >
                  <TwoArrows style={{ fill: "currentColor" }} />
                </Avatar>

                <Trans>Reschedule appointment</Trans>
              </Box>
            </Button>
          ) : (
            <Box>
              <Box
                sx={{
                  paddingX: { sx: 2, sm: 3 },
                }}
              >
                <Typography
                  fontSize="22px"
                  fontWeight="700"
                  textAlign="center"
                  pb={2}
                >
                  <Trans>
                    You have reached the limit for maximum nr of reschedules
                  </Trans>
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingX: { sx: 1, sm: 2 },
                  marginBottom: { sx: 1, sm: 2 },
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    borderRadius: "7px",
                    background: "rgba(248,244,231,0.2)",
                    backgroundColor: colors.zymegoLightTravertine,
                    padding: {
                      xs: 1,
                      sm: 2,
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      marginRight: theme.direction === "ltr" ? "8px" : "0px",
                      marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                      backgroundColor: colors.zymegoTravertine,
                      color: colors.zymegoDarkGreen,
                      fontSize: "large",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <PriorityHighIcon sx={{ fontSize: 20 }} />
                  </Avatar>

                  <Typography
                    align="left"
                    fontWeight="600"
                    fontSize="17px"
                    color={colors.zymegoDarkGreen}
                    sx={{
                      background: "rgba(0, 0, 0, 0)",
                    }}
                  >
                    <Trans>
                      According to the clinic's rules, you may reschedule a{" "}
                      <span style={{ fontSize: "17px", fontWeight: "700" }}>
                        maximum
                      </span>{" "}
                      of{" "}
                      <span style={{ fontSize: "17px", fontWeight: "700" }}>
                        {appointment?.bookingType?.maxRescheduleCount}
                      </span>{" "}
                      times. If you have any questions, contact your clinic.
                    </Trans>
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}

      {appointment?.canCancel && (
        <Box>
          <Button
            data-cy="manage-appointments-cancel-button"
            fullWidth
            size="large"
            variant="dialog"
            component={Link}
            to={getCancelLink({ appointment, hasNotExceededRescheduleLimit })}
            state={{ backgroundLocation }}
            disabled={
              (appointment?.cancelAppointmentRequestsByAppointmentId ?? [])
                .length > 0
            }
          >
            <Box
              sx={{
                margin: "auto",
                display: "inline-flex",
                alignItems: "center",
                gap: "24px",
                justifyContent: "flex-start",
              }}
            >
              <Avatar
                sx={{
                  width: "30px",
                  height: "30px",

                  color: colors.red,
                  backgroundColor: colors.zymegoPowder,
                }}
              >
                <CloseIcon />
              </Avatar>
              <Trans>Cancel appointment</Trans>
            </Box>
          </Button>
        </Box>
      )}
      {canOnlyCancel && (
        <InfoBox>
          <Trans>
            The appointment cannot be rescheduled digitally. This may be because
            of how the treatment and follow-up appointments are planned or due
            to scheduling complexity. Contact your clinic to reschedule.
          </Trans>
        </InfoBox>
      )}
      {canOnlyReschedule && (
        <InfoBox
          sx={{
            marginTop: 2,
          }}
        >
          <Trans>
            The appointment cannot be cancelled digitally. This may be because
            of how the treatment and follow-up appointments are planned or due
            to scheduling complexity. Contact your clinic to cancel.
          </Trans>
        </InfoBox>
      )}
    </DialogBottom>
  );
}
export { ManageAppointment };
