import { Trans } from "@lingui/macro";
import { Box, Button, Typography } from "@mui/material";

function HelpDialog({ onCloseClick }: { onCloseClick?: () => void }) {
  return (
    <Box
      sx={{
        padding: {
          xs: 2,
          sm: 3,
        },
        marginBottom: "24px",
      }}
    >
      <Typography variant="h6" gutterBottom>
        FAQ
      </Typography>
      <Typography gutterBottom>
        Mollis natoque mus conubia et id nostra mauris suscipit felis adipiscing
        congue.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Question
      </Typography>
      <Typography gutterBottom>
        Dolor posuere suspendisse malesuada ullamcorper at nostra proin pulvinar
        blandit porttitor donec odio eget tristique
      </Typography>
      <Typography variant="h6" gutterBottom>
        Question
      </Typography>
      <Typography gutterBottom>
        Dolor posuere suspendisse malesuada ullamcorper at nostra proin pulvinar
        blandit porttitor donec odio eget tristique
      </Typography>
      <Typography variant="h6" gutterBottom>
        Question 1?
      </Typography>
      <Typography gutterBottom>
        Dolor posuere suspendisse malesuada ullamcorper at nostra proin pulvinar
        blandit porttitor donec odio eget tristique
      </Typography>
      <Typography variant="h6" gutterBottom>
        Question 1?
      </Typography>
      <Typography gutterBottom>
        Dolor posuere suspendisse malesuada ullamcorper at nostra proin pulvinar
        blandit porttitor donec odio eget tristique
      </Typography>
      {onCloseClick ? (
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            onCloseClick();
          }}
        >
          <Trans>Ok, I got it</Trans>
        </Button>
      ) : null}
    </Box>
  );
}

export { HelpDialog };
