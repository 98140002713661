import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Box,
  Button as MuiButton,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";

import { trackEvent } from "../analytics";
import { Button } from "../components/button/button";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { ErrorComponent } from "../error";
import { Loading } from "../loading";
import { useGetNewAppointmentStatusQuery } from "./fetch-new-appointment-request-status.graphql";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { useGetTimeslotQuery } from "./get-timeslot.graphql";

function StatusNewAppointment() {
  const { formatDate, formatTime } = useDateFormatter();
  const location = useLocation();
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const [searchParameters] = useSearchParams();

  invariant(requestId, "requestId is missing from url");
  const timeslotId = searchParameters.get("timeslotId");
  invariant(timeslotId, "timeslot Id is not provided");

  const showDialogTimeoutHandle = useRef<ReturnType<typeof setTimeout>>();

  const {
    loading: statusLoading,
    error: statusError,
    data: statusData,
    stopPolling,
  } = useGetNewAppointmentStatusQuery({
    variables: { requestId },
    pollInterval: 200,
  });
  const {
    data: { patient } = {},
    loading: currentPatientLoading,
    error: currentPatientError,
  } = useGetCurrentPatientQuery();
  const {
    loading,
    error,
    data: { timeslot } = {},
  } = useGetTimeslotQuery({ variables: { timeslotId } });
  useEffect(() => {
    if (
      statusData &&
      statusData.createAppointmentRequest?.status === "SUCCESS"
    ) {
      trackEvent("new-appointment", {
        props: {
          careUnitId: statusData.createAppointmentRequest.careUnitId,
          careUnitName:
            statusData.createAppointmentRequest.careUnit?.name ?? "",
        },
      });
    }
  }, [statusData]);
  useEffect(() => {
    if (
      !statusLoading &&
      statusData?.createAppointmentRequest &&
      ["SUCCESS", "FAILED"].includes(statusData.createAppointmentRequest.status)
    ) {
      stopPolling();
    }
  }, [statusLoading, statusData, stopPolling]);
  useEffect(() => {
    if (
      statusData &&
      statusData.createAppointmentRequest?.status === "SUCCESS"
    ) {
      showDialogTimeoutHandle.current = setTimeout(() => {
        navigate(
          { pathname: "accept", search: `${searchParameters}` },
          {
            state: { backgroundLocation: location },
          },
        );
      }, 1000);
    }
    return () => {
      if (showDialogTimeoutHandle.current) {
        clearTimeout(showDialogTimeoutHandle.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData]);
  const handleBookAnotherAppointment = () => {
    searchParameters.delete("timeslotId");
    navigate({ pathname: "/bookings/new", search: `${searchParameters}` });
  };
  if (
    currentPatientError ||
    error ||
    statusError ||
    statusData?.createAppointmentRequest?.status === "FAILED"
  ) {
    return <ErrorComponent component="new-booking-error" />;
  }
  if (
    currentPatientLoading ||
    loading ||
    statusLoading ||
    (statusData?.createAppointmentRequest?.status === "IN_PROGRESS" && !loading)
  ) {
    return (
      <Box>
        <Loading text={t`creating appointment`} logo={false} />
      </Box>
    );
  }
  const bookingTypeName =
    timeslot?.bookingType?.visibleName ?? timeslot?.bookingType?.name;

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      flexGrow={1}
      sx={{ padding: { xs: 2, sm: 3 } }}
    >
      <Grid item>
        {statusData?.createAppointmentRequest?.careUnit
          ?.externalHomePageUrl && (
          <Box
            justifyContent="center"
            display="flex"
            onClick={() =>
              statusData?.createAppointmentRequest?.careUnit
                ?.externalHomePageUrl &&
              window.location.replace(
                statusData?.createAppointmentRequest?.careUnit
                  ?.externalHomePageUrl,
              )
            }
            color="rgba(34, 76, 79, 0.4)"
          >
            <Typography
              justifyContent="center"
              component="div"
              variant="boldBody2"
              sx={{ textTransform: "none", textOverflow: "ellips" }}
            >
              <Trans>
                To {statusData?.createAppointmentRequest?.careUnit?.name}'s
                website
              </Trans>
            </Typography>
            <ArrowForward fontSize="small" />
          </Box>
        )}
        <Card
          sx={{ border: "2px solid rgba(0 ,0 ,0 ,0.2)", borderRadius: "13px" }}
        >
          <Box sx={{ padding: { xs: 2, sm: 3 } }}>
            <Typography fontWeight={500} fontSize="23px">
              <Trans>You have booked an appointment!</Trans>
            </Typography>
          </Box>
          {timeslot && (
            <Box
              sx={{
                padding: { xs: 2, sm: 3 },
                borderTop: "2px solid rgba(0 ,0 ,0 ,0.1)",
                alignItems: "center",
              }}
            >
              <Typography variant="boldSubtitle1">
                <Trans>Appointment for {patient?.identifier}</Trans>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize="30px"
                  fontWeight={500}
                  sx={{ ":first-letter": { textTransform: "uppercase" } }}
                >
                  {formatDate(new Date(timeslot.startAtInCareUnitsTimezone))}
                </Typography>
                <Box
                  sx={{
                    marginLeft: "auto",
                    display: "inline-flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <AccessTimeIcon />
                  <Typography
                    fontWeight={500}
                    fontSize="20px"
                    sx={{ ":first-letter": { textTransform: "uppercase" } }}
                  >
                    {formatTime(new Date(timeslot.startAtInCareUnitsTimezone))}
                  </Typography>
                </Box>
              </Box>
              <Typography fontWeight={700} fontSize="15px">
                <Trans>with {timeslot?.caregiver?.name}</Trans>
                {bookingTypeName ? `, ${bookingTypeName}` : ""}
              </Typography>
            </Box>
          )}
          {timeslot && (
            <Box
              sx={{
                padding: { xs: 2, sm: 3 },
                borderTop: "2px solid rgba(0 ,0 ,0 ,0.1)",
              }}
            >
              <Typography
                fontWeight={700}
                fontSize="15px"
              >{`${timeslot.careUnit?.name}`}</Typography>
              <Typography
                fontWeight={700}
                fontSize="15px"
              >{`${timeslot.careUnit?.address}, ${timeslot.careUnit?.postAddress} ${timeslot.careUnit?.postCode}`}</Typography>
            </Box>
          )}
        </Card>
      </Grid>
      <Grid item margin={1}>
        <MuiButton
          variant="contained"
          color="secondary"
          onClick={handleBookAnotherAppointment}
          fullWidth
        >
          <Trans>Back to start</Trans>
        </MuiButton>
        <MuiButton
          fullWidth
          variant="contained"
          component={Link}
          to="/"
          disabled={loading || statusLoading}
          color="primary"
        >
          <Trans>My Appointments</Trans>
        </MuiButton>
      </Grid>
      <Grid item>
        <Button design="transparent" href="/logout" icon="logout">
          <Trans>Sign out</Trans>
        </Button>
      </Grid>
    </Grid>
  );
}
export { StatusNewAppointment };
