import { t, Trans } from "@lingui/macro";
import { Grid } from "@mui/material";
import { PatientNotificationTypes } from "graphql-let/__generated__/__types__";
import { Controller, useForm } from "react-hook-form";

import { Fieldset } from "../components/fieldset/fieldset";
import { Radio } from "../components/radio/radio";
import { isFeatureEnabled } from "../feature-flags";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { OnboardingView } from "./onboarding-view";
import { useUpdatePatientNotificationTypeMutation } from "./update-patient-notification-type.graphql";

interface FormValues {
  notificationType: PatientNotificationTypes;
}

function NotificationTypeInfo() {
  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  const [updatePatientNotificationType, { error: mutationError }] =
    useUpdatePatientNotificationTypeMutation();

  const {
    control,
    formState: { errors, isSubmitting, isDirty, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      notificationType: patient?.notificationType ?? undefined,
    },
  });

  if (isFeatureEnabled("consentForConfirmationsAndReminders") || loading) {
    return null;
  }

  if (error || mutationError || !patient) {
    throw new Error(t`Something went wrong`);
  }

  return (
    <OnboardingView isValid={isValid} step="notificationType">
      <OnboardingView.DescriptionText>
        <Trans>
          Choose if you want notifications via text message or email
        </Trans>
      </OnboardingView.DescriptionText>
      <OnboardingView.Stepper />
      <OnboardingView.Icon icon="bell" />
      <Grid item>
        <br />
      </Grid>
      <OnboardingView.Form
        handleSubmit={handleSubmit}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onSubmit={async (values): Promise<void> => {
          await updatePatientNotificationType({
            variables: {
              id: patient.id,
              notificationType: values.notificationType,
            },
          });
        }}
      >
        <Fieldset
          errorMessage={errors.notificationType?.message}
          label={t`I want notifications by`}
        >
          <Controller
            name="notificationType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Radio.Group {...field} aria-label="Notification">
                <Radio label={t`SMS`} value="SMS" />
                <Radio label={t`Email`} value="EMAIL" />
              </Radio.Group>
            )}
          />
        </Fieldset>
      </OnboardingView.Form>
    </OnboardingView>
  );
}
export { NotificationTypeInfo };
