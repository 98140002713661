import { t, Trans } from "@lingui/macro";
import { TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { OnboardingView } from "./onboarding-view";
import { useUpdatePatientEmailMutation } from "./update-patient-email.graphql";

interface FormValues {
  email: string;
}

export function EmailInfo() {
  const {
    data: { patient } = {},
    error,
    loading,
  } = useGetCurrentPatientQuery();

  const [updatePatientEmail, { error: mutationError }] =
    useUpdatePatientEmailMutation();

  const {
    control,
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    values: {
      email: patient?.email ?? "",
    },
  });

  if (loading) {
    return null;
  }

  if (error || mutationError || !patient) {
    throw new Error(t`Something went wrong`);
  }

  return (
    <OnboardingView isValid={isValid} step="email">
      <OnboardingView.DescriptionText>
        <Trans>
          First we need your contact information to send booking confirmations.
        </Trans>
      </OnboardingView.DescriptionText>
      <OnboardingView.Stepper />
      <OnboardingView.Icon icon="envelope" />
      <OnboardingView.ActionText>
        <Trans>Enter your email address</Trans>
      </OnboardingView.ActionText>
      <OnboardingView.Form
        handleSubmit={handleSubmit}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onSubmit={async (values): Promise<void> => {
          await updatePatientEmail({
            variables: { id: patient.id, email: values.email },
          });
        }}
      >
        <Controller
          name="email"
          control={control}
          rules={{
            required: t`Email is required.`,
            pattern: {
              value: /^.+@.+$/,
              message: t`Please provide a valid email address.`,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              sx={{ direction: "ltr" }}
              {...field}
              label={t`Email`}
              size="medium"
              fullWidth={true}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              type="email"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </OnboardingView.Form>
    </OnboardingView>
  );
}
