import { User } from "oidc-client-ts";

import { userManager } from "../user-manager";

type AuthenticatedUser = User & {
  readonly state?: AuthenticatedUserState;
};

type AuthenticatedUserPayload = ConstructorParameters<typeof User>[0] & {
  userState?: AuthenticatedUserState;
};

type AuthenticatedUserState = {
  locationState?: { historyState: unknown; pathname: string };
};

async function completeSignIn(): Promise<AuthenticatedUser | undefined> {
  const user = await userManager.signinCallback();
  return user as AuthenticatedUser | undefined;
}

function createUser(user: AuthenticatedUserPayload): AuthenticatedUser {
  return new User(user) as AuthenticatedUser;
}

async function getUser(): Promise<AuthenticatedUser | undefined> {
  const user = await userManager.getUser();
  return user as AuthenticatedUser | undefined;
}

function getUserStateFromLocalStorage(
  stateKey: string,
): AuthenticatedUserState | undefined {
  const stateString = globalThis.localStorage.getItem(`oidc.${stateKey}`);
  try {
    return stateString ? JSON.parse(stateString).data : undefined;
  } catch {
    return undefined;
  }
}

export type {
  AuthenticatedUser,
  AuthenticatedUserPayload,
  AuthenticatedUserState,
};
export { completeSignIn, createUser, getUser, getUserStateFromLocalStorage };
