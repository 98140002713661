import { Plural, t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import {
  CalendarPicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, isSameDay, isToday, isWeekend, parseISO } from "date-fns";
import { ar, cy, enGB, sv } from "date-fns/locale";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { trackEvent } from "../analytics";
import { colors, theme } from "../theme";
import { useGetAppointmentQuery } from "./get-appointment.graphql";
import { useWishListRequestMutation } from "./wish-list-request.graphql";

function WishList() {
  const { i18n } = useLingui();

  const defaultMaterialTheme = createTheme({
    palette: {
      text: {
        primary: "black",
      },
    },
  });
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const { appointmentId: id = "" } = useParams();

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: { appointment } = {},
    loading,
    error,
  } = useGetAppointmentQuery({
    variables: {
      id,
    },
  });
  const [wishlistRequest, { loading: mutationLoading, error: mutationError }] =
    useWishListRequestMutation();

  if (!loading && (error || !appointment)) {
    throw new Error(t`Could not find your appointment.`);
  }
  if (mutationError) {
    throw new Error(t`Could not save your wishes, please try again.`);
  }

  // NOTE: We need to use `enGB` if we want the week to start on a Monday.
  const adapterLocale = { ar, cy, en: enGB, sv }[i18n.locale];

  const maxDate = appointment?.wishlistWindow?.maxDate;
  const minDate = appointment?.wishlistWindow?.minDate;
  const requiredDays = appointment?.wishlistWindow?.requiredDays ?? 12;

  const handleSubmit = async () => {
    if (selectedDates.length < requiredDays) {
      setShowErrorMessage(true);
      return;
    }
    setShowErrorMessage(false);
    await wishlistRequest({
      variables: {
        appointmentId: id,
        selectedDates: selectedDates.map((selectedDate) =>
          format(selectedDate, "yyyy-MM-dd"),
        ),
      },
    });
    trackEvent("join-wishlist", {
      ...(appointment?.careUnit?.id
        ? {
            props: {
              careUnitId: appointment.careUnit.id,
              careUnitName: appointment.careUnit.name,
            },
          }
        : {}),
    });
    navigate(`confirm`, {
      state: { backgroundLocation: location },
    });
  };

  const renderPickerDays = (
    date: Date,
    _selectedDates: (Date | null)[],
    pickersDayProperties: PickersDayProps<Date>,
    // eslint-disable-next-line max-params
  ) => {
    const isActive = selectedDates.some((s) => isSameDay(s, date));

    return (
      <PickersDay
        {...pickersDayProperties}
        sx={{
          backgroundColor: isActive
            ? colors.zymegoWarmGreen
            : isToday(date)
            ? "#E5E5E5"
            : undefined,
          borderRadius: 2,
          color: isActive ? colors.white : colors.zymegoDarkGreen,
          font: "inherit",
          fontSize: 13,
          fontWeight: "medium",
          transition: theme.transitions.create(["background-color", "color"]),
          "&:focus": {
            backgroundColor: isActive ? colors.zymegoWarmGreen : colors.white,
            color: isActive ? colors.white : colors.zymegoDarkGreen,
          },
          "&:hover": {
            backgroundColor: isActive
              ? colors.zymegoWarmGreen
              : colors.zymegoSpearMint,
            color: isActive ? colors.white : colors.zymegoDarkGreen,
          },
        }}
      />
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justifyContent="space-between"
      flexGrow={1}
      sx={{
        paddingX: { xs: 2, sm: 3 },
        paddingBottom: { xs: 2, sm: 3 },
      }}
    >
      <Grid item sx={{ paddingX: 1, paddingTop: 4 }}>
        <Typography align="center" sx={{ marginBottom: 1 }} variant="h4">
          <Trans>Please select suitable dates</Trans>
        </Typography>
        {showErrorMessage ? (
          <>
            <Typography align="center" color="error" variant="h5">
              <Trans>NOTE!</Trans>
            </Typography>
            <Typography align="center" color="error" variant="h6">
              <Plural
                value={requiredDays}
                one="You need to select at least one day to be able to proceed."
                other="You need to select a minimum of # days to be able to proceed."
              />
            </Typography>
          </>
        ) : (
          <Typography
            align="center"
            variant="subtitle2"
            sx={{ opacity: "60%" }}
            px={2}
          >
            <Trans>
              You can choose to keep or cancel your current appointment when you
              have chosen suitable days.
            </Trans>
          </Typography>
        )}
      </Grid>
      <Grid
        item
        sx={{
          direction: "ltr",
          width: "100%",
          // TODO: Figure out how to style the CalendarPicker directly, or upgrade the package.
          "& .MuiCalendarPicker-root": {
            marginX: 0,
            width: "100%",
            "& *": {},
          },
          "& .MuiPickersCalendarHeader-label": {
            fontWeight: "medium",
          },
          "& .MuiPickersCalendarHeader-labelContainer": {
            font: "inherit",
          },
          "& .MuiPickersCalendarHeader-root": {
            marginTop: 0,
          },
        }}
      >
        <ThemeProvider theme={defaultMaterialTheme}>
          <LocalizationProvider
            adapterLocale={adapterLocale}
            dateAdapter={AdapterDateFns}
          >
            <CalendarPicker
              views={["day"]}
              disableHighlightToday
              minDate={minDate ? parseISO(minDate) : undefined}
              maxDate={maxDate ? parseISO(maxDate) : undefined}
              disablePast={true}
              date={null}
              renderDay={renderPickerDays}
              shouldDisableDate={(date) => isWeekend(date) || isToday(date)}
              onChange={(date) => {
                if (!date) {
                  return;
                }
                const isSelected = selectedDates.some((selectedDate) =>
                  isSameDay(selectedDate, date),
                );

                if (isSelected) {
                  setSelectedDates((oldSelectedDates) =>
                    oldSelectedDates.filter(
                      (selectedDate) => !isSameDay(selectedDate, date),
                    ),
                  );
                } else {
                  setSelectedDates((oldSelectedDates) => [
                    ...oldSelectedDates,
                    date,
                  ]);
                }
              }}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </Grid>

      <Grid item paddingBottom={3}>
        <Button
          data-cy="wishlist-confirm-button"
          variant="contained"
          fullWidth
          size="large"
          type="button"
          onClick={() => handleSubmit()}
          disabled={mutationLoading}
        >
          <Trans>Confirm choice</Trans>
        </Button>
      </Grid>
    </Grid>
  );
}
export { WishList };
