import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Link as MuiLink, TextField, Typography } from "@mui/material";
import { PatientNotificationTypes } from "graphql-let/__generated__/__types__";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { Control, Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { ConsentForConfirmationsAndRemindersField } from "../consent/consent-for-confirmations-and-reminders-field";
import { DialogBottom } from "../dialog-bottom";
import { isFeatureEnabled } from "../feature-flags";
import { useGuessCurrentCountryCode } from "../hooks";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { NotificationTypeField } from "../notifications/notification-type-field";
import { useGetTimeslotAndCurrentPatientQuery } from "../reschedule/get-timeslot-and-current-patient.graphql";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { useCancellationRequestMutation } from "./cancellation-request.graphql";
import { useUpdatePatientMutation } from "./update-patient.graphql";

interface FormValues {
  email: string;
  notificationType: PatientNotificationTypes;
  phoneNumber?: string;
  consentForConfirmationsAndReminders?: "true" | "false";
}

function ContactInformation() {
  const backgroundLocation = useBackgroundLocation();
  const { appointmentId = "" } = useParams();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const countryCode = useGuessCurrentCountryCode();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
  } = useForm<FormValues>();

  const {
    error,
    loading,
    data: { timeslot: appointment, patient } = {},
  } = useGetTimeslotAndCurrentPatientQuery({
    variables: { timeslotId: appointmentId },
  });

  const [
    updatePatientRequest,
    { error: updatePatientError, loading: updatePatientLoading },
  ] = useUpdatePatientMutation();
  const [
    cancellationRequest,
    { loading: cancellationLoading, error: cancellationError },
  ] = useCancellationRequestMutation();

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  if (loading && (!appointment || !patient)) {
    return null;
  }

  if (error || (!loading && !appointment) || !patient) {
    throw new Error(t`Something went wrong while fetching your information.`);
  }
  if (updatePatientError || cancellationError) {
    throw new Error(t`Something went wrong while processing your request.`);
  }

  const onSubmit: SubmitHandler<FormValues> = async ({
    email,
    notificationType,
    phoneNumber: formattedPhoneNumber = "",
    consentForConfirmationsAndReminders,
  }) => {
    // If empty string is passed, it will be converted to undefined

    if (!patient) {
      return;
    }

    const phoneNumber =
      parsePhoneNumberFromString(formattedPhoneNumber)?.number ?? null;

    if (!notificationType) {
      notificationType = phoneNumber ? "SMS" : "EMAIL";
    }

    try {
      await updatePatientRequest({
        variables: {
          email,
          notificationType,
          phoneNumber,
          patientId: patient.id,
          consentForConfirmationsAndReminders:
            consentForConfirmationsAndReminders == null
              ? undefined
              : consentForConfirmationsAndReminders === "true",
        },
      });
    } catch {
      // Ignore
    }

    const { data: cancellationData } = await cancellationRequest({
      variables: {
        appointmentId,
      },
    });

    navigate(
      `../status/${cancellationData?.cancelAppointment?.cancelAppointmentRequest?.id}`,
      { state: { backgroundLocation } },
    );
  };

  return (
    <DialogBottom
      open={isDialogOpen}
      onClose={handleDialogClose}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        {theme.direction === "ltr" ? (
          <ArrowBackIos fontSize="small" />
        ) : (
          <ArrowForwardIos fontSize="small" />
        )}
        <MuiLink
          onClick={() => navigate(-1)}
          underline="none"
          color={colors.blue}
        >
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          paddingBottom: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box
            sx={{
              paddingX: {
                xs: 1,
                sm: 2,
              },
            }}
          >
            <Typography variant="h4" align="center" marginBottom={2}>
              <Trans>Confirmation will be sent to</Trans>
            </Typography>
            <Typography
              variant="subtitle2"
              align="center"
              sx={{ opacity: "60%" }}
            >
              <Trans>
                If a timeslot that suits you becomes available, we'll send you a
                booking confirmation
              </Trans>
            </Typography>

            <Typography
              variant="subtitle2"
              align="center"
              sx={{ opacity: "60%" }}
            >
              <Trans>Please confirm your contact details</Trans>
            </Typography>
            <Box sx={{ marginBottom: "15px" }}>
              <Controller
                name="email"
                control={control}
                defaultValue={patient?.email ?? ""}
                rules={{
                  required: t`Email is required.`,
                  pattern: {
                    value: /^.+@.+$/,
                    message: t`Please provide a valid email address.`,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{ direction: "ltr" }}
                    {...field}
                    label={t`Email`}
                    size="medium"
                    fullWidth={true}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    type="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />

              <Controller
                render={({ field, fieldState }) => {
                  return (
                    <MuiTelInput
                      sx={{ direction: "ltr" }}
                      {...field}
                      fullWidth
                      label={t`Phone Number`}
                      helperText={errors.phoneNumber?.message}
                      error={Boolean(fieldState.error)}
                      preferredCountries={[
                        ...(countryCode ? [countryCode as CountryCode] : []),
                        "SE",
                      ]}
                      defaultCountry={(countryCode as CountryCode) || "SE"}
                      forceCallingCode
                    />
                  );
                }}
                name="phoneNumber"
                control={control}
                defaultValue={patient.phoneNumber ?? ""}
                rules={{
                  validate(value?: string) {
                    const notificationType = getValues("notificationType");
                    if (notificationType === "SMS" && !value) {
                      return t`Phone number is required.`;
                    }
                    if (
                      notificationType === "SMS" &&
                      value &&
                      !matchIsValidTel(value)
                    )
                      return t`Please provide the phone number in a valid format, example: +46701234567`;
                  },
                }}
              />
            </Box>
            <Box>
              {isFeatureEnabled("consentForConfirmationsAndReminders") ? (
                <ConsentForConfirmationsAndRemindersField
                  control={control as unknown as Control}
                  showDescription
                />
              ) : (
                <NotificationTypeField
                  control={control as unknown as Control}
                  defaultValue={patient.notificationType}
                  errorMessage={errors.notificationType?.message}
                />
              )}
            </Box>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight style={{ fill: "currentColor" }} />
                ) : (
                  <ArrowLeft style={{ fill: "currentcolor" }} />
                )
              }
              loading={
                isSubmitting || updatePatientLoading || cancellationLoading
              }
              loadingPosition="end"
            >
              <Trans>Confirm</Trans>
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </DialogBottom>
  );
}

export { ContactInformation };
