query GetShortlink($shortLink: String!) {
  newAppointmentLinkByShortlink(shortLink: $shortLink) {
    id
    careUnits {
      urlFriendlyName
    }
    bookingTypeId
    caregiverId
  }
}
