import { Alert, AlertProps, AlertTitle } from "@mui/material";
import { MessageTypes } from "graphql-let/__generated__/__types__";
import { useState } from "react";

import { MessageFieldsFragment } from "../appointments-and-offers-list/get-appointments-and-offers-list.graphql";
import { useDismissMessageMutation } from "./dismiss-message.graphql";

function messageAlertSeverity(type: MessageTypes): AlertProps["severity"] {
  switch (type) {
    case "INFO":
      return "info";
    case "WARNING":
      return "warning";
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unexpected message type ${exhaustiveCheck}`);
    }
  }
}

function sortInfoFirst(messages: MessageFieldsFragment[]) {
  // eslint-disable-next-line max-params
  return [...(messages ?? [])].sort(({ type: a }, { type: b }) =>
    a < b ? -1 : a > b ? 1 : 0,
  );
}

export function MessageList({
  messages,
}: {
  messages: MessageFieldsFragment[];
}) {
  const [dismissedMessages, setDismissedMessages] = useState<string[]>([]);

  const [dismissMessage] = useDismissMessageMutation({
    refetchQueries: ["GetAppointmentAndOfferList"],
  });

  const handleDismissMessage = (id: string) => {
    dismissMessage({ variables: { id } });
    setDismissedMessages((dismissed) => [...dismissed, id]);
  };

  return (
    <>
      {sortInfoFirst(messages ?? [])
        .filter((message) => !dismissedMessages.includes(message.id))
        // sort by type - warning before info
        .map((message) => (
          <Alert
            key={message.id}
            lang={message.language}
            severity={messageAlertSeverity(message.type)}
            onClose={
              message.type === "INFO"
                ? () => handleDismissMessage(message.id)
                : undefined // warnings can not be dismissed
            }
          >
            {message.title && <AlertTitle>{message.title}</AlertTitle>}
            {message.message}
          </Alert>
        ))}
    </>
  );
}
