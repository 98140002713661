import { Box, BoxProps } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DialogBottom } from "./dialog-bottom";
import { Header } from "./header";
import { HelpDialog } from "./help/help-dialog";

function Layout({
  header = true,
  headerColor = "dark",
  headerSticky = false,
  headerHideLogo = false,
  headerOnBackClick = false,
  headerOnAddGuardianDependantClick = false,
  headerOnSwitchProfileClick = false,
  headerHideContact = false,
  children,
  sx,
}: PropsWithChildren<
  BoxProps & {
    header?: boolean;
    headerColor?: "dark" | "light";
    headerSticky?: boolean;
    headerHideLogo?: boolean;
    headerOnBackClick?: boolean | string;
    headerOnAddGuardianDependantClick?: boolean;
    headerOnSwitchProfileClick?: boolean;
    headerHideContact?: boolean;
  }
>) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleBackClick = headerOnBackClick
    ? () => {
        if (typeof headerOnBackClick === "string") {
          navigate(headerOnBackClick);
        } else {
          navigate(-1);
        }
      }
    : undefined;

  const handleSwitchProfileClick = headerOnSwitchProfileClick
    ? () => {
        navigate("/");
      }
    : undefined;

  const handleAddGuardianDependantClick = headerOnAddGuardianDependantClick
    ? () => {
        navigate("add-guardianship", {
          state: { backgroundLocation: location },
        });
      }
    : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        padding: "0",
        background: "#ffffff",
        width: "100%",
        maxWidth: "444px",
        height: "100%",
        minHeight: "100vh",
        overflow: "clip",
        position: "relative",
        ...sx,
      }}
    >
      {header && (
        <Header
          color={headerColor}
          sticky={headerSticky}
          hideLogo={headerHideLogo}
          onBackClick={handleBackClick}
          onAddGuardianDependantClick={handleAddGuardianDependantClick}
          onSwitchProfileClick={handleSwitchProfileClick}
          hideContact={headerHideContact}
        />
      )}
      {children}
      <DialogBottom
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <HelpDialog onCloseClick={() => setDialogOpen(false)} />
      </DialogBottom>
    </Box>
  );
}

export { Layout };
