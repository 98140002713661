import { differenceInHours } from "date-fns";
import { BookingType, Features } from "graphql-let/__generated__/__types__";
import type { Location } from "history";
import { Context, useContext } from "react";

interface CareUnitEnabledFeatures {
  enabledFeatures: { name: Features }[];
}

interface GroupedTimeslots<Timeslot> {
  [Month: string]: {
    date: Date;
    items: {
      [Day: string]: {
        date: Date;
        items: {
          [Time: string]: {
            date: Date;
            timeslot: Timeslot;
          };
        };
      };
    };
  };
}

interface PartialAppointment {
  id: string;
  startAt: string | null;
  canReschedule: boolean | null;
  bookingType?: {
    maxRescheduleCount: number | null;
  } | null;
  rescheduleCount?: number | null;
  onWaitlist: boolean | null;
  onWishlist: boolean | null;
}

function isWaitlistEnabledOnCareUnit(
  careUnit: CareUnitEnabledFeatures,
): boolean {
  return careUnit.enabledFeatures.some(({ name }) => name === "WAITLIST");
}

function isWishlistEnabledOnCareUnit(
  careUnit: CareUnitEnabledFeatures,
): boolean {
  return careUnit.enabledFeatures.some(({ name }) => name === "WISHLIST");
}

function isWishlistEnabled({
  careUnit,
  bookingType,
}: {
  careUnit: CareUnitEnabledFeatures;
  bookingType: Pick<BookingType, "wishlistEnabled">;
}): boolean {
  return bookingType.wishlistEnabled && isWishlistEnabledOnCareUnit(careUnit);
}

function hasAppointmentExceededRescheduleLimit(
  appointment: PartialAppointment | null | undefined,
): boolean {
  if (!appointment?.bookingType) return false;

  const {
    rescheduleCount,
    bookingType: { maxRescheduleCount },
  } = appointment;

  if (maxRescheduleCount === null) return false;

  return (rescheduleCount ?? 0) >= maxRescheduleCount;
}

function isAppointmentWaitlistable(
  appointment: PartialAppointment & {
    careUnit: CareUnitEnabledFeatures | null;
  },
): boolean {
  if (
    appointment.startAt === null ||
    appointment.canReschedule === null ||
    !appointment.bookingType ||
    !appointment.careUnit
  ) {
    throw new Error(
      `Appointment is missing required fields ${JSON.stringify(appointment)}`,
    );
  }

  // TODO: Use `isLateAppointmentChange` function?
  const laterThan24hoursInFuture =
    differenceInHours(new Date(appointment.startAt), new Date()) > 24;

  const hasExceededRescheduleLimit =
    hasAppointmentExceededRescheduleLimit(appointment);

  return (
    laterThan24hoursInFuture &&
    appointment.canReschedule &&
    isWaitlistEnabledOnCareUnit(appointment.careUnit) &&
    !appointment.onWaitlist &&
    !(
      isWishlistEnabledOnCareUnit(appointment.careUnit) &&
      appointment.onWishlist
    ) &&
    !hasExceededRescheduleLimit
  );
}

function newAppointmentFlowByLocation(location: Location): boolean {
  if (!location.search) return false;

  const parameters = new URLSearchParams(location.search);
  return parameters.has("clinicName");
}

function deepLinkWithProvidedCaregiverId(location: Location): boolean {
  const parameters = new URLSearchParams(location.search);
  return parameters.has("caregiverProvided");
}

function deepLinkWithProvidedBookingTypeId(location: Location): boolean {
  const parameters = new URLSearchParams(location.search);
  return parameters.has("bookingTypeProvided");
}

function splitByComma(parameter: string | null): string[] {
  return parameter ? parameter.split(",") : [];
}

function useDefinedContext<T>({
  context,
  hookName,
  providerName,
}: {
  context: Context<T | undefined>;
  hookName: string;
  providerName: string;
}): T {
  const contextValue = useContext(context);

  if (contextValue === undefined) {
    throw new Error(`${hookName}() must be used within a <${providerName} />`);
  }

  return contextValue;
}

export {
  deepLinkWithProvidedBookingTypeId,
  deepLinkWithProvidedCaregiverId,
  hasAppointmentExceededRescheduleLimit,
  isAppointmentWaitlistable,
  isWaitlistEnabledOnCareUnit,
  isWishlistEnabled,
  newAppointmentFlowByLocation,
  splitByComma,
  useDefinedContext,
};
export type { GroupedTimeslots };
