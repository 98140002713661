import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { useDateFormatter } from "../datetime/use-date-formatter";
import { ErrorComponent } from "../error";
import { useFormPersist } from "../react-hook-form-persist";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { useGetTimeslotQuery } from "./get-timeslot.graphql";

interface FormValues {
  reason: string;
}

const maxReasonLength = 250;

function BookingInformationNewAppointment() {
  const { formatDateTime } = useDateFormatter();
  const [searchParameters] = useSearchParams();

  const timeslotId = searchParameters.get("timeslotId");
  invariant(timeslotId, "timeslot Id is not provided");
  const {
    loading,
    error,
    data: { timeslot } = {},
  } = useGetTimeslotQuery({
    variables: { timeslotId },
  });

  const {
    data: { patient } = {},
    loading: currentPatientLoading,
    error: currentPatientError,
  } = useGetCurrentPatientQuery({ fetchPolicy: "network-only" });

  const navigate = useNavigate();
  const location = useLocation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  useFormPersist({ key: "patient-information", watch, setValue });
  const data = watch();

  if (currentPatientLoading && !patient) {
    return null;
  }

  if (
    error ||
    (!timeslot && !loading) ||
    !patient ||
    !timeslotId ||
    currentPatientError
  ) {
    return <ErrorComponent component="new-booking-error" />;
  }

  const bookingTypeName =
    timeslot?.bookingType?.visibleName ?? timeslot?.bookingType?.name;

  const onSubmit = async () => {
    navigate({ pathname: "../contact-information", search: location.search });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ flex: "auto", display: "flex" }}
    >
      <Box
        display="flex"
        textAlign="center"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        sx={{
          paddingX: {
            xs: 2,
            sm: 3,
          },
          paddingBottom: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <Box>
          {timeslot ? (
            <Box>
              {timeslot.careUnit && (
                <Typography fontSize="21px" fontWeight="500">
                  <Trans>{timeslot.careUnit.name}</Trans>
                </Typography>
              )}
              <Typography
                marginY={1}
                variant="h6"
                align="center"
                sx={{
                  ":first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                {formatDateTime(new Date(timeslot.startAtInCareUnitsTimezone))}
              </Typography>

              <Typography variant="subtitle2" fontWeight={700} align="center">
                <Trans>with</Trans> {timeslot.caregiver?.name}
                {bookingTypeName ? `, ${bookingTypeName}` : ""}
              </Typography>
            </Box>
          ) : undefined}

          <TextField
            inputProps={{ readOnly: true }}
            defaultValue={patient.identifier}
            placeholder={t`YYYYMMDD-XXXX`}
            label={t`Personal identity number`}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#ADB9AF26",
              },
            }}
            size="medium"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Controller
            name="reason"
            defaultValue=""
            control={control}
            rules={{
              required: t`Reason is required.`,
            }}
            render={({ field }) => (
              <TextField
                placeholder={t`Describe your issues as clear as you can`}
                size="medium"
                error={Boolean(errors.reason)}
                helperText={errors.reason?.message}
                onChange={(event) => field.onChange(event.target.value)}
                multiline
                fullWidth
                rows={7}
                defaultValue={data.reason}
                label={
                  t`Reason for appointment` +
                  " " +
                  t`(${field.value.length} / ${maxReasonLength} characters)`
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ maxLength: maxReasonLength }}
              />
            )}
          />
        </Box>

        <Button
          variant="contained"
          fullWidth
          type="submit"
          endIcon={<ArrowForward />}
        >
          <Trans>Next</Trans>
        </Button>
      </Box>
    </form>
  );
}

export { BookingInformationNewAppointment };
