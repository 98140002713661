import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSessionStorageValue } from "@react-hookz/web";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { Control, Controller, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { ConsentForConfirmationsAndRemindersField } from "../consent/consent-for-confirmations-and-reminders-field";
import { useDateFormatter } from "../datetime/use-date-formatter";
import { ErrorComponent } from "../error";
import { isFeatureEnabled } from "../feature-flags";
import { useGuessCurrentCountryCode } from "../hooks";
import { NewAppointmentLayout } from "../new-appointment-layout";
import { useFormPersist } from "../react-hook-form-persist";
import { colors } from "../theme";
import { useNewAppointmentAndUpdatePatientMutation } from "./create-appointment-and-update-patient.graphql";
import { useGetCurrentPatientQuery } from "./get-current-patient.graphql";
import { useGetTimeslotQuery } from "./get-timeslot.graphql";

interface FormValues {
  consentForConfirmationsAndReminders?: "false" | "true";
  email: string;
  phoneNumber: string;
}
interface PersistData {
  reason?: string;
}
function ContactInformationNewAppointment() {
  const { formatDate, formatTime } = useDateFormatter();
  const countryCode = useGuessCurrentCountryCode();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();

  const timeslotId = searchParameters.get("timeslotId");
  invariant(timeslotId, "timeslot Id is not provided");
  const sessionStorageValue = useSessionStorageValue<PersistData>(
    "patient-information",
  );
  const reason = sessionStorageValue.value?.reason ?? "";

  const shortlinkIdent = searchParameters.get("link");

  const {
    loading,
    error,
    data: { timeslot: selectedTimeslot } = {},
  } = useGetTimeslotQuery({
    variables: { timeslotId },
  });

  const [
    createAppointmentAndUpdatePatient,
    { loading: mutationLoading, error: mutationError },
  ] = useNewAppointmentAndUpdatePatientMutation();

  const {
    data: { patient } = {},
    loading: currentPatientLoading,
    error: currentPatientError,
  } = useGetCurrentPatientQuery();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onChange",
  });

  useFormPersist({ key: "contact-information", watch, setValue });

  if (error || !patient || !timeslotId || mutationError) {
    return (
      <NewAppointmentLayout>
        <ErrorComponent component="new-booking-error" />
      </NewAppointmentLayout>
    );
  }

  if (currentPatientLoading && !patient) {
    return null;
  }

  if (
    (!selectedTimeslot && !loading) ||
    currentPatientError ||
    !patient ||
    mutationError ||
    !timeslotId
  ) {
    return <ErrorComponent component="new-booking-error" />;
  }

  const bookingTypeName =
    selectedTimeslot?.bookingType?.visibleName ??
    selectedTimeslot?.bookingType?.name;

  const onSubmit: SubmitHandler<FormValues> = async ({
    email,
    consentForConfirmationsAndReminders,
    phoneNumber: formattedPhoneNumber = "",
  }) => {
    //If empty string is passed, it will be converted to undefined

    const phoneNumber =
      parsePhoneNumberFromString(formattedPhoneNumber)?.number ?? undefined;

    try {
      const { data: mutationData } = await createAppointmentAndUpdatePatient({
        variables: {
          consentForConfirmationsAndReminders:
            consentForConfirmationsAndReminders === undefined
              ? undefined
              : consentForConfirmationsAndReminders === "true",
          timeslotId,
          reason,
          patientId: patient.id,
          email,
          phoneNumber,
          shortlinkIdent,
        },
      });
      navigate(
        {
          pathname: `../status/${mutationData?.createAppointment?.createAppointmentRequest?.id}`,
          search: location.search,
        },
        { replace: true },
      );
    } catch (caughtError) {
      console.error(caughtError);
      // but ignore, status page will show error
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ flex: "auto", display: "flex" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        sx={{
          paddingX: {
            xs: 2,
            sm: 3,
          },
          paddingBottom: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <Box>
          <Box sx={{ mb: 2 }}>
            {selectedTimeslot ? (
              <Box>
                <Typography align="center" fontSize="21px" fontWeight="500">
                  {selectedTimeslot.careUnit && (
                    <Trans>{selectedTimeslot.careUnit.name}</Trans>
                  )}
                </Typography>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    ":first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                >
                  {formatDate(
                    new Date(selectedTimeslot.startAtInCareUnitsTimezone),
                  )}{" "}
                  &bull;{" "}
                  {formatTime(
                    new Date(selectedTimeslot.startAtInCareUnitsTimezone),
                  )}
                </Typography>
                <Typography align="center" fontWeight="medium" variant="body2">
                  <Trans>with</Trans> {selectedTimeslot.caregiver?.name}
                  {bookingTypeName ? `, ${bookingTypeName}` : ""}
                </Typography>
              </Box>
            ) : (
              <Typography
                fontSize="21px"
                fontWeight="700"
                color={colors.zymegoWarmGreen}
              >
                <Trans>Submit contact details</Trans>
              </Typography>
            )}
          </Box>
          <TextField
            inputProps={{ readOnly: true }}
            defaultValue={patient.identifier}
            placeholder={t`YYYYMMDD-XXXX`}
            label={t`Personal identity number`}
            size="medium"
            fullWidth={true}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#ADB9AF26",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Controller
            render={({ field, fieldState }) => {
              return (
                <MuiTelInput
                  sx={{ direction: "ltr" }}
                  {...field}
                  fullWidth
                  label={t`Mobile phone`}
                  helperText={errors.phoneNumber?.message}
                  error={Boolean(fieldState.error)}
                  preferredCountries={[
                    ...(countryCode ? [countryCode as CountryCode] : []),
                    "SE",
                  ]}
                  defaultCountry={(countryCode as CountryCode) || "SE"}
                  forceCallingCode
                />
              );
            }}
            defaultValue={patient.phoneNumber ?? ""}
            name="phoneNumber"
            control={control}
            rules={{
              required: t`Phone number is required`,
              validate(value) {
                if (matchIsValidTel(value)) {
                  return true;
                }

                return t`Please provide the phone number in a valid format, example: +46701234567`;
              },
            }}
          />
          <Controller
            name="email"
            control={control}
            defaultValue={patient.email ?? ""}
            rules={{
              required: t`Email is required.`,
              pattern: {
                value: /^.+@.+$/,
                message: t`Please provide a valid email address.`,
              },
            }}
            render={({ field }) => (
              <TextField
                sx={{ direction: "ltr" }}
                {...field}
                label={t`Email`}
                size="medium"
                fullWidth={true}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          {isFeatureEnabled("consentForConfirmationsAndReminders") && (
            <ConsentForConfirmationsAndRemindersField
              control={control as unknown as Control}
              showDescription
            />
          )}
        </Box>

        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={mutationLoading}
          endIcon={mutationLoading ? <CircularProgress /> : <ArrowForward />}
        >
          <Trans>Next</Trans>
        </Button>
      </Box>
    </form>
  );
}

export { ContactInformationNewAppointment };
