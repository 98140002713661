import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Avatar, Box, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

import { colors, theme } from "../theme";

interface InfoBoxProperties {
  children: ReactNode;
  sx?: SxProps;
}

/**
 * InfoBox component
 * @param param0 children to be displayed in the info box
 * @returns a styled info box with an info icon and the children
 *
 * TODO: Move this to UI once UI is ready
 */

function InfoBox({ children, sx }: InfoBoxProperties) {
  return (
    <Box
      sx={{
        paddingX: { sx: 1, sm: 2 },
        marginBottom: { sx: 1, sm: 2 },
        ...sx,
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          backgroundColor: colors.zymegoLightTravertine,
          borderRadius: "7px",
          display: "inline-flex",
          justifyContent: "center",
          padding: {
            xs: 1,
            sm: 2,
          },
          textAlign: "center",
        }}
      >
        <Avatar
          sx={{
            alignItems: "center",
            backgroundColor: "transparent",
            color: colors.zymegoDarkGreen,
            display: "inline-flex",
            fontSize: "large",
            height: "24px",
            justifyContent: "center",
            marginLeft: theme.direction === "rtl" ? "8px" : "0px",
            marginRight: theme.direction === "ltr" ? "8px" : "0px",
            textAlign: "center",
            width: "24px",
          }}
        >
          <InfoOutlined sx={{ fontSize: 24 }} />
        </Avatar>

        <Typography
          align="left"
          fontWeight="600"
          fontSize="17px"
          color={colors.zymegoDarkGreen}
          sx={{ background: "rgba(0, 0, 0, 0)" }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
}

export { InfoBox };
