import { t, Trans } from "@lingui/macro";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button as MuiButton,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { DialogBottom } from "../dialog-bottom";
import { ErrorComponent } from "../error";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { getConfig } from "../runtime-config";
import { colors, theme } from "../theme";
import { useContactSupportMutation } from "./contact-support.graphql";
import { useGetCareUnitsQuery } from "./get-care-units.graphql";
import {
  GetCurrentPatientAndGuardianshipsQuery,
  useGetCurrentPatientAndGuardianshipsQuery,
} from "./get-current-patient-and-guardianships.graphql";

const { ALLOW_GUARDIANSHIP } = getConfig();

type DataQueryProperties = {
  data: GetCurrentPatientAndGuardianshipsQuery | undefined;
};

interface CareUnitFetchStatus {
  [key: string]: {
    status: boolean;
    count: number;
    careUnitName: string;
  };
}

enum uiStateEnum {
  NO_APPOINTMENTS,
  NO_APPOINTMENTS_POPUP,
  STILL_NO_APPOINTMENTS,
  SOMETHING_WENT_WRONG,
  WE_COULD_NOT_FIND_ANY_APPOINTMENT,
}

function NoBookingMessage({
  fetchStatus,
}: {
  fetchStatus: CareUnitFetchStatus | null | undefined;
}) {
  const location = useLocation();
  const [uiState, setUIState] = useState(identifyFetchStates(fetchStatus));
  const [selectedCareUnit, setSelectedCareUnit] = useState<string>("");
  const { data, error, loading } = useGetCurrentPatientAndGuardianshipsQuery();

  const [
    contactSupportMutation,
    {
      loading: contactSupportLoading,
      error: contactSupportError,
      data: contactSupportData,
    },
  ] = useContactSupportMutation();

  const {
    data: careUnitData,
    error: careUnitError,
    loading: careUnitLoading,
  } = useGetCareUnitsQuery();

  if (loading || careUnitLoading || contactSupportLoading) {
    return null;
  }

  if (error || careUnitError || !data || !careUnitData || contactSupportError) {
    throw new Error(t`Something went wrong`);
  }

  const handleCloseOrExit = () => {
    setUIState(uiStateEnum.NO_APPOINTMENTS);

    // eslint-disable-next-line unicorn/no-useless-undefined
    setSelectedCareUnit("");

    const selectElement = document.querySelector(
      "#selCareUnit",
    ) as HTMLSelectElement | null;
    setTimeout(() => {
      if (selectElement) {
        selectElement.blur();
      }
    }, 0);
  };

  const handleNextClick = () => {
    setUIState(uiStateEnum.STILL_NO_APPOINTMENTS);
    contactSupportMutation({
      variables: {
        careUnitName: selectedCareUnit,
      },
    });
  };

  function identifyFetchStates(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    fetchStatus: CareUnitFetchStatus | null | undefined = {},
  ) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    function tallyFetchStatistics(fetchStatus: CareUnitFetchStatus = {}) {
      let totalValidStatus = 0;

      for (const clinicId in fetchStatus) {
        const clinicData = fetchStatus[clinicId];

        if (clinicData && clinicData.status) {
          totalValidStatus++;
        }
      }

      return { totalValidStatus };
    }

    if (fetchStatus === null || Object.keys(fetchStatus).length === 0) {
      return uiStateEnum.SOMETHING_WENT_WRONG;
    }

    const { totalValidStatus } = tallyFetchStatistics(fetchStatus);
    const careUnitsCount = Object.keys(fetchStatus).length;
    const isSuccess = careUnitsCount === totalValidStatus;
    const isFailure = totalValidStatus === 0;

    if (isSuccess) {
      return uiStateEnum.NO_APPOINTMENTS;
    } else if (isFailure) {
      return uiStateEnum.SOMETHING_WENT_WRONG;
    } else {
      // PARTIALLY SUCCESSFUL
      return uiStateEnum.WE_COULD_NOT_FIND_ANY_APPOINTMENT;
    }
  }

  function extractFailedCareUnits() {
    return Object.entries(fetchStatus || {})
      .filter(([, entry]) => entry.status === false)
      .map(([id, entry]) => ({ id, careUnitName: entry.careUnitName }));
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function NoAppointments({ data }: DataQueryProperties) {
    return (
      <Typography
        component="div"
        align="center"
        fontSize="22px"
        fontWeight="700"
        lineHeight="30.8px"
        data-cy="no-appointments"
      >
        {data?.currentPatient?.identifier ? (
          <Trans>
            We couldn't find any appointment for{" "}
            {data.currentPatient.identifier.slice(0, -4)}-
            {data.currentPatient.identifier.slice(-4)}
          </Trans>
        ) : (
          <Trans>We couldn't find any of your appointments.</Trans>
        )}
      </Typography>
    );
  }

  function StillNoAppointments() {
    if (!contactSupportData?.contactSupport?.success) {
      console.error("Unexpected error while sending contact-support message!");
    }
    return (
      <Typography
        data-cy="still-no-appointments-message"
        align="center"
        component="div"
        fontSize="22px"
        fontWeight="700"
        lineHeight="30.8px"
      >
        <Trans>We're sorry, but we still couldn't find any appointment</Trans>
        <Typography
          fontSize="16px"
          fontWeight="500"
          letterSpacing="-0.024em"
          lineHeight="22.4px"
          marginTop="8px"
        >
          <Trans>
            We will contact the clinic shortly to make sure all their
            appointments are connected to Zymego.
          </Trans>
        </Typography>
      </Typography>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function WeCouldNotFindAnyAppointment({ data }: DataQueryProperties) {
    const failedCareUnits = extractFailedCareUnits();
    return (
      <Typography
        align="center"
        component="div"
        data-cy="no-appointments-found"
        fontSize="22px"
        fontWeight="700"
        lineHeight="30.8px"
      >
        {data?.currentPatient?.identifier ? (
          <Trans>
            We couldn't find any appointment for{" "}
            {data.currentPatient.identifier.slice(0, -4)}-
            {data.currentPatient.identifier.slice(-4)}
          </Trans>
        ) : (
          <Trans>We couldn't find any of your appointments.</Trans>
        )}
        <Typography
          component="div"
          fontSize="16px"
          fontWeight="500"
          letterSpacing="-0.024em"
          lineHeight="22.4px"
        >
          <Trans>
            We are experiencing a temporary issue when fetching appointments
            from the clinics in the list below. If you have an appointment at
            one of those clinics, please contact your clinic until the issue is
            resolved.
          </Trans>

          {failedCareUnits.length > 0 && (
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "17px",
                marginTop: "16px",
              }}
            >
              <List
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                {failedCareUnits
                  // eslint-disable-next-line max-params
                  .map(({ id, careUnitName }, index) => {
                    const charactersPerRow = 36;
                    const baseHeight = 30; // base height for up to 36 characters
                    const additionalHeightPerRow = 30;
                    const numberOfRows = Math.ceil(
                      careUnitName.length / charactersPerRow,
                    );
                    const dynamicHeight = `${
                      baseHeight + (numberOfRows - 1) * additionalHeightPerRow
                    }px`;
                    return (
                      <Fragment key={id}>
                        <Box height={dynamicHeight}>
                          <ListItem
                            sx={{
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <ListItemText>{careUnitName}</ListItemText>
                          </ListItem>
                        </Box>
                        {index < failedCareUnits.length - 1 && <Divider />}
                      </Fragment>
                    );
                  })}
              </List>
            </Box>
          )}
        </Typography>
      </Typography>
    );
  }

  function FailStatePanel() {
    switch (uiState) {
      case uiStateEnum.NO_APPOINTMENTS:
      case uiStateEnum.NO_APPOINTMENTS_POPUP:
        return <NoAppointments data={data} />;
      case uiStateEnum.STILL_NO_APPOINTMENTS:
        return <StillNoAppointments />;
      case uiStateEnum.SOMETHING_WENT_WRONG:
        return (
          <ErrorComponent
            inline
            errorMessage={t`We are experiencing a temporary issue when fetching appointments.`}
          />
        );
      case uiStateEnum.WE_COULD_NOT_FIND_ANY_APPOINTMENT:
        return <WeCouldNotFindAnyAppointment data={data} />;
      default: {
        const exhaustiveCheck: never = uiState;
        console.error(`Unexpected UI Error State - ${exhaustiveCheck}`);
        return (
          <ErrorComponent
            inline
            error={new Error(t`Unexpected UI Error State - ${exhaustiveCheck}`)}
          />
        );
      }
    }
  }

  return (
    <>
      <Grid
        item
        container
        direction="column"
        alignItems="stretch"
        justifyContent="space-between"
        spacing={{
          xs: 3,
          sm: 4,
        }}
        flexGrow={1}
        sx={{
          padding: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <Grid item>
          <Card>
            <Box
              sx={{
                padding: {
                  xs: 2,
                  sm: 3,
                },
              }}
            >
              <FailStatePanel />
            </Box>
            {data.guardianships?.length !== 0 && (
              <Box
                sx={{
                  padding: {
                    xs: 1,
                    sm: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "start",
                    justifyContent: "center",
                    textAlign: "center",
                    borderRadius: "7px",
                    backgroundColor: colors.zymegoLightTravertine,
                    padding: {
                      xs: 1,
                      sm: 2,
                    },
                    gap: "8px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: colors.zymegoTravertine,
                      color: colors.zymegoDarkGreen,
                      fontSize: "large",
                    }}
                  >
                    <PriorityHighIcon sx={{ fontSize: 20 }} />
                  </Avatar>
                  <Box sx={{ display: "block" }}>
                    <Typography variant="h6" textAlign="start">
                      <Trans>
                        Switch to your child's profile or add another child in
                        the drop-down menu above (below the Zymego logo)
                      </Trans>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Card>
        </Grid>
        {ALLOW_GUARDIANSHIP && data.guardianships?.length === 0 && (
          <Grid item>
            <Card>
              <Box
                sx={{
                  padding: {
                    xs: 2,
                    sm: 3,
                  },
                }}
              >
                <Typography fontSize="22px" align="center" fontWeight="700">
                  <Trans>Need to manage your child's appointment?</Trans>
                </Typography>
                <MuiButton
                  fullWidth
                  endIcon={
                    theme.direction === "ltr" ? (
                      <ArrowRight fill={"currentColor"} />
                    ) : (
                      <ArrowLeft fill={"currentColor"} />
                    )
                  }
                  variant="contained"
                  component={Link}
                  to="/add-guardianship"
                  state={{
                    backgroundLocation: location,
                  }}
                >
                  <Trans>Add child</Trans>
                </MuiButton>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
      {uiState === uiStateEnum.NO_APPOINTMENTS_POPUP && (
        <DialogBottom
          onClose={handleCloseOrExit}
          onExited={handleCloseOrExit}
          open
        >
          <Box
            sx={{
              padding: { xs: 2, sm: 3 },
              marginBottom: "24px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                padding: { xs: 2, sm: 3 },
              }}
            >
              <Typography variant="h3">
                <Trans>
                  You need to have a booked appointment to use Zymego
                </Trans>
              </Typography>
            </Box>
            <Box
              sx={{
                padding: { xs: 2, sm: 3 },
              }}
            >
              <Typography>
                <Trans>
                  If you have a booked appointment at {selectedCareUnit}, can we
                  contact you to begin troubleshooting?
                </Trans>
              </Typography>
            </Box>
            <Box
              sx={{
                padding: { xs: 2, sm: 3 },
                paddingTop: { xs: 1, sm: 2 },
              }}
            >
              <LoadingButton
                data-cy="still-no-appointments-confirm-contact-button"
                fullWidth
                variant="contained"
                onClick={handleNextClick}
                loading={contactSupportLoading}
              >
                <Trans>Zymego may contact me</Trans>
              </LoadingButton>
            </Box>
            <Box
              sx={{
                padding: { xs: 1, sm: 1 },
              }}
            >
              <Typography variant="h6" gutterBottom>
                <Trans>Zymego is a booking system, not a clinic</Trans>
              </Typography>
            </Box>
          </Box>
        </DialogBottom>
      )}
    </>
  );
}

export { NoBookingMessage };
