import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { Layout } from "../layout";
import { Loading } from "../loading";
import { useGetShortlinkQuery } from "./get-shortlink.graphql";

function createNavigationParameters({
  careUnitNames,
  bookingTypeId,
  caregiverId,
}: {
  careUnitNames?: string[];
  bookingTypeId: (string | null)[] | null;
  caregiverId: (string | null)[] | null;
}): string | undefined {
  if (
    careUnitNames?.length === 0 &&
    bookingTypeId?.length === 0 &&
    caregiverId?.length === 0
  ) {
    return undefined;
  }

  const parameters = new URLSearchParams();

  if (careUnitNames && careUnitNames.length > 0) {
    parameters.append("clinicName", careUnitNames.join(","));
  }

  if (bookingTypeId && bookingTypeId.length > 0) {
    parameters.append("bookingTypeId", bookingTypeId.join(","));
  }

  if (caregiverId && caregiverId.length > 0) {
    parameters.append("caregiverId", caregiverId.join(","));
  }

  return `?${parameters.toString()}`;
}

function NewAppointmentShortlink() {
  const navigate = useNavigate();

  const { shortLink } = useParams();
  invariant(shortLink, "Shortlink is required");

  const [searchParameters] = useSearchParams();

  const { loading, data } = useGetShortlinkQuery({
    variables: { shortLink },
  });

  useEffect(() => {
    if (!loading && data?.newAppointmentLinkByShortlink) {
      const { careUnits, bookingTypeId, caregiverId } =
        data.newAppointmentLinkByShortlink;
      const careUnitNames = careUnits?.map(
        (unit) => unit.urlFriendlyName ?? "",
      );
      const navigationParameters = createNavigationParameters({
        careUnitNames,
        bookingTypeId,
        caregiverId,
      });
      navigate(
        {
          pathname: "../bookings/new",
          search: `${navigationParameters}`,
        },
        { replace: true },
      );
    }
  }, [loading, data, navigate, shortLink, searchParameters]);

  if (!loading && !data?.newAppointmentLinkByShortlink?.id) {
    throw new Error(t`Invalid appointment link!`);
  }

  return (
    <Layout headerColor="light">
      <Box
        sx={{
          padding: 3,
        }}
      >
        <Loading text={t`Loading...`} logo={false} />
      </Box>
    </Layout>
  );
}

export { NewAppointmentShortlink };
