import { ApmRoutes } from "@elastic/apm-rum-react";
import { useSessionStorageValue } from "@react-hookz/web";
import { useEffect } from "react";
import { Location, Route, useLocation, useNavigate } from "react-router-dom";

import { ConfirmLateAppointment } from "./appointment/confirm-late-appointment";
import { ManageAppointment } from "./appointment/manage";
import { AskWaitlistDialog } from "./appointments-and-offers-list/appointment/join-waitlist-dialog";
import { AppointmentsAndOffers } from "./appointments-and-offers-list/appointments-offers-list";
import { OfferList } from "./appointments-and-offers-list/offer/offer-list";
import { CancelReason } from "./cancel/cancel-reason";
import { Cancellation } from "./cancel/cancellation";
import { CancellationAnotherAppointment } from "./cancel/cancellation-another-appointment";
import { CancellationToWishlist } from "./cancel/cancellation-to-wishlist";
import { CancellationConfirmation } from "./cancel/confirm";
import { ContactInformation } from "./cancel/contact-information";
import { Request } from "./cancel/request";
import { CancellationStatus } from "./cancel/status";
import { ConfirmationsAndRemindersConsentDialog } from "./consent/confirmations-and-reminders-consent-dialog";
import { Consent } from "./consent/consent-dialog";
import { ContactZymego } from "./contact-zymego/contact";
import { FeedbackDialog } from "./feedback/feedback";
import { useGetProfilesQuery } from "./get-profiles.graphql";
import { AddGuardianshipDialog } from "./guardianship/add-guardianship-dialog";
import { AddGuardianshipStatus } from "./guardianship/add-guardianship-status";
import { ProfilesList } from "./guardianship/profiles-list";
import { SwitchSessionStatus } from "./guardianship/switch-session-status";
import { Layout } from "./layout";
import { NewAppointmentShortlink } from "./new-appointment/shortlink";
import { NotFound } from "./not-found";
import { ConfirmationsAndRemindersConsentInfo } from "./onboarding/confirmations-and-reminders-consent-info";
import { EmailInfo } from "./onboarding/email-info";
import { NotificationTypeInfo } from "./onboarding/notification-type-info";
import { Onboarding } from "./onboarding/onboarding";
import { PhoneNumberInfo } from "./onboarding/phone-info";
import { PrivateRouteConsent } from "./protected-routes/consent";
import { PrivateRouteLogIn } from "./protected-routes/onboarding";
import { RescheduleConfirmationDialog } from "./reschedule/confirmation-dialog";
import { ManagePatientInformationInReschedule } from "./reschedule/contact-information-dialog";
import { InfoMaxReschedules } from "./reschedule/info-max-reschedules-dialog";
import { AskWaitlistDialogInReschedule } from "./reschedule/join-waitlist-dialog";
import { Reschedule } from "./reschedule/reschedule";
import { RescheduleStatus } from "./reschedule/status";
import { BackgroundLocationProvider } from "./router/background-location-context";
import { getConfig } from "./runtime-config";
import { JoinWaitlist } from "./waitlist/join";
import { LeaveWaitlistDialog } from "./waitlist/leave-waitlist";
import { LeaveWaitlistStatusDialog } from "./waitlist/leave-waitlist-status";
import { WaitlistStatusDialog } from "./waitlist/status-dialog";
import { CancellationConfirmationInWishlist } from "./wishlist/cancellation-confirmation";
import { ManagePatientInformationInWishlist } from "./wishlist/contact-information-dialog";
import { LeaveWishlistDialog } from "./wishlist/leave-wishlist";
import { LeaveWishlistStatusDialog } from "./wishlist/leave-wishlist-status";
import { WishListStatus } from "./wishlist/status";
import { WelcomeWishlist } from "./wishlist/welcome";
import { WishList } from "./wishlist/wish-list";

const { ALLOW_GUARDIANSHIP } = getConfig();

function App() {
  const { data } = useGetProfilesQuery({ fetchPolicy: "cache-and-network" });

  const location = useLocation();
  const navigate = useNavigate();
  const hasRedirectedToSelectProfile = useSessionStorageValue(
    "hasRedirectedToSelectProfile",
    { defaultValue: false },
  );

  useEffect(() => {
    if (
      hasRedirectedToSelectProfile.value === false &&
      (data?.guardianships?.length ?? 0) !== 0
    ) {
      navigate("/select-profile");
      hasRedirectedToSelectProfile.set(true);
    }
  }, [data, hasRedirectedToSelectProfile, navigate]);

  const state = location.state as { backgroundLocation?: Location } | undefined;
  const backgroundLocation = state?.backgroundLocation;

  return (
    <>
      {/* Fullscreen routes */}
      <ApmRoutes location={backgroundLocation ?? location}>
        <Route path="n/:shortLink" element={<NewAppointmentShortlink />} />
        <Route path="consent">
          <Route index element={<Consent />} />
          <Route
            path="welcome-back"
            element={<ConfirmationsAndRemindersConsentDialog />}
          />
          <Route
            path="confirmations-and-reminders"
            element={<ConfirmationsAndRemindersConsentInfo hideOtherSteps />}
          />
        </Route>
        <Route element={<PrivateRouteConsent />}>
          <Route path="onboarding">
            <Route index element={<Onboarding />} />
            <Route path="email" element={<EmailInfo />} />
            <Route path="phone-number" element={<PhoneNumberInfo />} />
            <Route
              path="notification-type"
              element={<NotificationTypeInfo />}
            />
            <Route
              path="consent"
              element={<ConfirmationsAndRemindersConsentInfo />}
            />
          </Route>
          <Route element={<PrivateRouteLogIn />}>
            <Route
              path="/"
              element={
                <Layout
                  sx={{
                    justifyContent: "stretch",
                    backgroundColor: "#EDEDED",
                  }}
                  headerColor="light"
                  headerOnSwitchProfileClick={ALLOW_GUARDIANSHIP}
                  headerOnAddGuardianDependantClick={
                    ALLOW_GUARDIANSHIP && data?.guardianships?.length === 0
                  }
                >
                  <AppointmentsAndOffers />
                </Layout>
              }
            />

            <Route path="appointment/:appointmentId">
              <Route path="reschedule">
                <Route
                  index
                  element={
                    <Layout headerOnBackClick>
                      <Reschedule />
                    </Layout>
                  }
                />

                <Route path=":timeslotId">
                  <Route path="status/:rescheduleAppointmentRequestId">
                    <Route
                      index
                      element={
                        <Layout>
                          <RescheduleStatus />
                        </Layout>
                      }
                    />
                  </Route>
                </Route>
              </Route>

              <Route path="wishlist">
                <Route
                  index
                  element={
                    <Layout headerOnBackClick>
                      <WishList />
                    </Layout>
                  }
                />

                <Route path="status">
                  <Route
                    index
                    element={
                      <Layout>
                        <WishListStatus />
                      </Layout>
                    }
                  />

                  <Route
                    path=":cancelAppointmentRequestId"
                    element={
                      <Layout>
                        <WishListStatus />
                      </Layout>
                    }
                  />
                </Route>

                <Route path="leave">
                  <Route index element={<LeaveWishlistDialog />} />
                </Route>
              </Route>
            </Route>

            <Route
              path="offers"
              element={
                <Layout headerOnBackClick>
                  <OfferList />
                </Layout>
              }
            />
          </Route>
          {
            // No need to change profile when guardianship is disabled
            ALLOW_GUARDIANSHIP && (
              <>
                <Route
                  path="guardianship/:id/status"
                  element={<AddGuardianshipStatus />}
                />
                <Route
                  path="switch-session/:id/status"
                  element={<SwitchSessionStatus />}
                />
                <Route
                  path="select-profile"
                  element={
                    <Layout
                      sx={{
                        justifyContent: "stretch",
                        backgroundColor: "#EDEDED",
                      }}
                    >
                      <ProfilesList />
                    </Layout>
                  }
                />
              </>
            )
          }
        </Route>
        <Route path="*" element={<NotFound />} />
      </ApmRoutes>

      {/* Bottom dialog routes */}
      {backgroundLocation ? (
        <BackgroundLocationProvider backgroundLocation={backgroundLocation}>
          <ApmRoutes>
            <Route element={<PrivateRouteConsent />}>
              <Route element={<PrivateRouteLogIn />}>
                <Route path="appointment/:appointmentId">
                  <Route
                    path="confirm-late-appointment"
                    element={<ConfirmLateAppointment />}
                  />
                  <Route path="manage" element={<ManageAppointment />} />
                  <Route path="waitlist">
                    <Route path="ask" element={<AskWaitlistDialog />} />
                    <Route path="join" element={<JoinWaitlist />} />
                    <Route path="leave">
                      <Route index element={<LeaveWaitlistDialog />} />
                      <Route
                        path="status"
                        element={<LeaveWaitlistStatusDialog />}
                      />
                    </Route>
                    <Route path="status" element={<WaitlistStatusDialog />} />
                  </Route>
                  <Route path="wishlist">
                    <Route path="leave">
                      <Route index element={<LeaveWishlistDialog />} />
                      <Route
                        path="status"
                        element={<LeaveWishlistStatusDialog />}
                      />
                    </Route>
                    <Route path="welcome" element={<WelcomeWishlist />} />

                    <Route
                      path="confirm"
                      element={<CancellationConfirmationInWishlist />}
                    />
                    <Route
                      path="contact-info/:isCancelled"
                      element={<ManagePatientInformationInWishlist />}
                    />
                  </Route>
                  <Route path="cancel">
                    <Route index element={<Cancellation />} />
                    <Route
                      path="another-appointment"
                      element={<CancellationAnotherAppointment />}
                    />
                    <Route
                      path="cancellation-to-wishlist"
                      element={<CancellationToWishlist />}
                    />
                    <Route
                      path="contact-info"
                      element={<ContactInformation />}
                    />
                    <Route path="request" element={<Request />} />
                    <Route path="reason" element={<CancelReason />} />
                    <Route
                      path="confirm"
                      element={<CancellationConfirmation />}
                    />
                    <Route
                      path="status/:cancelAppointmentRequestId"
                      element={<CancellationStatus />}
                    />
                  </Route>
                  <Route path="reschedule">
                    <Route path="info" element={<InfoMaxReschedules />} />
                    <Route path=":timeslotId">
                      <Route
                        path="confirm"
                        element={<RescheduleConfirmationDialog />}
                      />
                      <Route
                        path="contact-info"
                        element={<ManagePatientInformationInReschedule />}
                      />
                      <Route path="status/:rescheduleAppointmentRequestId">
                        <Route
                          path="join"
                          element={<AskWaitlistDialogInReschedule />}
                        />
                        <Route
                          path="feedback"
                          element={
                            <Layout>
                              <FeedbackDialog />
                            </Layout>
                          }
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route
                path="contact"
                element={
                  <Layout>
                    <ContactZymego />
                  </Layout>
                }
              />
            </Route>

            {ALLOW_GUARDIANSHIP && (
              <Route
                path="add-guardianship"
                element={
                  <Layout>
                    <AddGuardianshipDialog />
                  </Layout>
                }
              />
            )}
          </ApmRoutes>
        </BackgroundLocationProvider>
      ) : null}
    </>
  );
}

export { App };
