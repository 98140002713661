import { getConfig, GetConfigOptions } from "./runtime-config";

type Geography = (typeof GEOGRAPHIES)[number];

type GeographyConfig = {
  defaultLocale: LocaleName;
  enabledFeatures: {
    lateAppointmentChanges: boolean;
  };
  locales: LocaleName[];
};

type LocaleName = "ar" | "cy" | "en" | "sv";

const GEOGRAPHIES = ["SE", "UK"] as const;

const geographyConfigs: Record<Geography, GeographyConfig> = {
  SE: {
    defaultLocale: "sv",
    enabledFeatures: {
      lateAppointmentChanges: true,
    },
    locales: ["ar", "en", "sv"],
  },
  UK: {
    defaultLocale: "en",
    enabledFeatures: {
      lateAppointmentChanges: false,
    },
    locales: ["en", "cy"],
  },
};

const getGeographyConfig = (options?: GetConfigOptions): GeographyConfig => {
  const { GEOGRAPHY } = getConfig(options);
  return geographyConfigs[GEOGRAPHY];
};

export { GEOGRAPHIES, getGeographyConfig };
export type { Geography, GeographyConfig, LocaleName };
