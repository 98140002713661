import { t, Trans } from "@lingui/macro";
import { Grid, Typography } from "@mui/material";

import { ErrorComponent } from "../../error";
import { Loading } from "../../loading";
import { useGetReferralsQuery } from "./get-referrals.graphql";
import { Referral } from "./referral";

function ReferralsList() {
  const { data, loading, error } = useGetReferralsQuery();

  if (error) {
    return (
      <ErrorComponent
        errorMessage={t`Something went wrong while fetching your referrals.`}
      />
    );
  }

  if (loading || !data) {
    return (
      <Loading text={t`Please wait while we are fetching your referrals.`} />
    );
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="stretch"
      spacing={{
        xs: 2,
      }}
      sx={{
        paddingX: {
          xs: 2,
          sm: 3,
        },
        paddingY: {
          xs: 1,
          sm: 2,
        },
      }}
    >
      <Typography
        variant="caption"
        component="div"
        sx={{
          textTransform: "uppercase",
          textAlign: "center",
          fontWeight: 700,
          letterSpacing: "0.166em",
          paddingBottom: {
            xs: 1,
            sm: 2,
          },
        }}
      >
        <Trans>Handle referral</Trans>
      </Typography>

      {data.referrals?.map((referral) => (
        <Grid item key={referral.id}>
          <Referral referral={referral} />
        </Grid>
      ))}
    </Grid>
  );
}
export { ReferralsList };
