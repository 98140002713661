import { t, Trans } from "@lingui/macro";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { Button } from "../components/button/button";
import { Loading } from "../loading";
import { MessageList } from "../messages/message-list";
import { AppointmentsList } from "./appointment/appointments-list";
import { useFetchAppointmentsMutation } from "./fetch-appointments.graphql";
import { useGetAppointmentAndOfferListLazyQuery } from "./get-appointments-and-offers-list.graphql";
import { OfferNotification } from "./offer/offer-notification-card";
import { ReferralsList } from "./referral/referral-list";

function AppointmentsAndOffers() {
  const fetchAppointmentsRequestCompletedStates = [
    "FAILED",
    "PARTIALLY_SUCCESSFUL",
    "SUCCESSFUL",
  ];

  const [
    fetchAppointments,
    {
      data: mutationData,
      error: mutationError,
      loading: mutationLoading,
      called: mutationCalled,
    },
  ] = useFetchAppointmentsMutation();

  const [
    getAppointmentsAndOffersList,
    {
      data: queryData,
      error: queryError,
      loading: queryLoading,
      called: queryCalled,
    },
  ] = useGetAppointmentAndOfferListLazyQuery({
    pollInterval: 5 * 1000,
  });

  const activeOffers = queryData?.offers?.filter(
    (offer) => offer.status === "UNDECIDED",
  );

  useEffect(() => {
    let timeoutHandle: ReturnType<typeof setTimeout>;

    (async function fetchAppointmentsEvery5Minutes() {
      await fetchAppointments();
      timeoutHandle = setTimeout(fetchAppointmentsEvery5Minutes, 5 * 60 * 1000);
    })();

    return () => clearTimeout(timeoutHandle);
  }, [fetchAppointments]);

  const [hasTimedOut, setHasTimedOut] = useState(false);

  const timeoutHandle = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (mutationData?.fetchAppointments?.fetchAppointmentsRequest?.id) {
      getAppointmentsAndOffersList({
        variables: {
          fetchAppointmentsRequestId:
            mutationData.fetchAppointments.fetchAppointmentsRequest.id,
        },
      });

      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current);
      }

      timeoutHandle.current = setTimeout(() => {
        setHasTimedOut(true);
      }, 5 * 60 * 1000);

      return () => clearTimeout(timeoutHandle.current as unknown as number);
    }
  }, [getAppointmentsAndOffersList, mutationData]);

  const loading =
    !mutationCalled || !queryCalled || mutationLoading || queryLoading;

  const fetchAppointmentsRequestStatus =
    queryData?.fetchAppointmentsRequest?.status || "QUEUED";
  const fetchingAppointments = fetchAppointmentsRequestCompletedStates.includes(
    fetchAppointmentsRequestStatus,
  )
    ? false
    : Boolean(queryData?.fetchAppointmentsRequest);

  if (
    !loading &&
    (!fetchingAppointments ||
      queryData?.fetchAppointmentsRequest?.status === "FAILED")
  ) {
    clearTimeout(timeoutHandle.current as unknown as number);
  }

  const fetchStatus = queryData?.fetchAppointmentsRequest?.fetchStatus;

  if (mutationError || queryError || hasTimedOut) {
    throw new Error(t`Something went wrong while fetching your appointments.`);
  }

  let loadingText = t`Loading...`;
  if (fetchingAppointments) {
    loadingText = t`We are fetching your appointments from the patient record system, so it may take a while.`;
  }

  const fetchAppointmentsRequestId = queryData?.fetchAppointmentsRequest?.id;

  return (
    <>
      {/* the Box is here so that margins on messages can collapse */}
      <Box>
        <MessageList messages={queryData?.globalMessages ?? []} />
      </Box>
      {loading || fetchingAppointments || !fetchAppointmentsRequestId ? (
        <Box
          sx={{
            padding: 3,
          }}
        >
          <Loading text={loadingText} logo={false} />
        </Box>
      ) : (
        <>
          {activeOffers && activeOffers.length > 0 && <OfferNotification />}

          {queryData && queryData.appointments && (
            <AppointmentsList
              appointments={queryData.appointments}
              fetchAppointmentsRequestId={fetchAppointmentsRequestId}
              fetchStatus={fetchStatus}
            />
          )}
          {queryData &&
            queryData.referrals &&
            queryData.referrals.length > 0 && <ReferralsList />}
          <Box>
            <Button design="transparent" href="/logout" icon="logout">
              <Trans>Sign out</Trans>
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

export { AppointmentsAndOffers };
