import { Trans } from "@lingui/macro";
import { ArrowForward, Close as CloseIcon } from "@mui/icons-material";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { useDateFormatter } from "../datetime/use-date-formatter";
import { DialogBottom } from "../dialog-bottom";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors } from "../theme";
import { useGetTimeslotQuery } from "./get-timeslot.graphql";

function AcceptEarlierAppointmentOverlay() {
  const backgroundLocation = useBackgroundLocation();
  const { formatDateTime } = useDateFormatter();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();

  const timeslotId = searchParameters.get("timeslotId");
  invariant(timeslotId, "timeslot Id is not provided");

  const [isDialogOpen, setIsDialogOpen] = useState(true);
  function handleDialogClose() {
    setIsDialogOpen(false);
  }
  function handleYesPleaseClick() {
    setIsDialogOpen(false);
    navigate("/");
  }

  const { loading, data: { timeslot } = {} } = useGetTimeslotQuery({
    variables: { timeslotId },
  });

  return (
    <DialogBottom
      open={isDialogOpen}
      onClose={handleDialogClose}
      onExited={() => {
        navigate(
          {
            pathname: backgroundLocation.pathname,
            search: `${searchParameters}`,
          },
          { replace: true },
        );
      }}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontSize: "18px",
        }}
      ></Box>
      {timeslot && (
        <Box
          sx={{
            padding: 1,
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h4" align="center" fontWeight="600">
            <Trans>You have booked an appointment!</Trans>
          </Typography>
          <Typography
            margin={1}
            variant="h5"
            color="secondary"
            align="center"
            sx={{ ":first-letter": { textTransform: "uppercase" } }}
          >
            {formatDateTime(new Date(timeslot.startAtInCareUnitsTimezone))}
          </Typography>
        </Box>
      )}

      <Box sx={{ padding: 1, borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
        <Typography
          variant="h6"
          align="center"
          fontWeight="500"
          sx={{ padding: "0 24px 8px 24px" }}
        >
          <Trans>
            Would you be interested if an earlier time slot becomes available?
          </Trans>
        </Typography>
        <Typography
          fontSize="15px"
          fontWeight="500"
          align="center"
          sx={{ opacity: "60%", color: colors.zymegoDarkGreen }}
        >
          <Trans>We can send offers of earlier time slots</Trans>
        </Typography>
      </Box>
      <Box
        sx={{
          padding: { xs: 2, sm: 3 },
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          fullWidth
          onClick={handleYesPleaseClick}
          sx={{ fontSize: "22px", fontWeight: "700" }}
          endIcon={<ArrowForward style={{ fill: "white" }} />}
        >
          <Trans>Yes, please</Trans>
        </Button>
      </Box>
      <Box>
        <Button fullWidth size="large" onClick={handleDialogClose}>
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 1,
              fontSize: "16px",
              fontWeight: "700",
              color: "black",
              textTransform: "none",
            }}
          >
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
                color: colors.red,
                backgroundColor: colors.zymegoPowder,
              }}
            >
              <CloseIcon />
            </Avatar>
            <Trans>No, thank you</Trans>
          </Box>
        </Button>
      </Box>
    </DialogBottom>
  );
}
export { AcceptEarlierAppointmentOverlay };
