import { useLingui } from "@lingui/react";
import { getCountryForTimezone } from "countries-and-timezones";
import { CountryCode } from "libphonenumber-js";
import { useEffect, useState } from "react";

function useGuessCurrentCountryCode(): CountryCode | undefined {
  const { i18n } = useLingui();
  const [country, setCountry] = useState<CountryCode>();

  useEffect(() => {
    let timeZone: string | undefined;

    try {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {
      // ignore
    }

    if (timeZone) {
      const countryFromTimezone = getCountryForTimezone(timeZone)?.id;

      if (countryFromTimezone) {
        setCountry(countryFromTimezone.toUpperCase() as CountryCode);
        return;
      }
    }

    const userLang = i18n.locale;
    const countryFromLang = userLang.split("-")[1];

    if (countryFromLang) {
      setCountry(countryFromLang.toUpperCase() as CountryCode);
      return;
    }
  }, [i18n.locale]);

  return country;
}

export { useGuessCurrentCountryCode };
