import { t, Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";

import { Fieldset } from "../components/fieldset/fieldset";
import { Radio } from "../components/radio/radio";
import { useGetCurrentPatientQuery } from "../onboarding/get-current-patient.graphql";
import { colors } from "../theme";

export function ConsentForConfirmationsAndRemindersField({
  control,
  showDescription,
}: {
  control: Control<{ consentForConfirmationsAndReminders?: "true" | "false" }>;
  showDescription?: boolean;
}) {
  const {
    error,
    loading,
    data: { patient } = {},
  } = useGetCurrentPatientQuery();

  if (loading && !patient) {
    return null;
  }

  if (error || !patient) {
    throw new Error(t`Something went wrong`);
  }

  return (
    <Box>
      <Fieldset label={`${t`I consent`} *`}>
        <Controller
          name="consentForConfirmationsAndReminders"
          control={control}
          rules={{ required: t`Please select a value.` }}
          defaultValue={
            patient.consentForConfirmationsAndReminders ? "true" : "false"
          }
          render={({ field }) => (
            <Radio.Group {...field} aria-label="Consent">
              <Radio label={t`Yes`} value="true" />
              <Radio label={t`No`} value="false" />
            </Radio.Group>
          )}
        />
      </Fieldset>

      {showDescription && (
        <Typography
          display="block"
          variant="caption"
          sx={{
            color: colors.zymegoStone,
            lineHeight: 1.4,
            textAlign: "left",
            marginTop: "10px",
          }}
        >
          *{" "}
          <Trans>
            We need your consent for Zymego to send out booking confirmations
            and reminders. You always have the right to withdraw your consent.
          </Trans>
        </Typography>
      )}
    </Box>
  );
}
