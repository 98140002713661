import { useLingui } from "@lingui/react";

type Urls = {
  bookings: {
    new: string;
  };
  consent: {
    confirmationsAndReminders: string;
    index: string;
    welcomeBack: string;
  };
  index: string;
  logout: string;
  onboarding: {
    consent: string;
    email: string;
    index: string;
    notificationType: string;
    phoneNumber: string;
  };
  privacyPolicy: string;
  termsOfService: string;
};

export const useUrls = (): Urls => {
  const {
    i18n: { locale },
  } = useLingui();

  return {
    bookings: {
      new: "/bookings/new",
    },
    consent: {
      confirmationsAndReminders: "/consent/confirmations-and-reminders",
      index: "/consent",
      welcomeBack: "/consent/welcome-back",
    },
    index: "/",
    logout: "/logout",
    onboarding: {
      consent: "/onboarding/consent",
      email: "/onboarding/email",
      index: "/onboarding",
      notificationType: "/onboarding/notification-type",
      phoneNumber: "/onboarding/phone-number",
    },
    privacyPolicy:
      locale === "sv"
        ? "https://www.zymego.com/sv/integritetspolicy"
        : "https://www.zymego.com/privacy-policy",
    termsOfService:
      locale === "sv"
        ? "https://www.zymego.com/sv/anvandarvillkor"
        : "https://www.zymego.com/terms-of-service",
  };
};
