import { t, Trans } from "@lingui/macro";
import { Link as MuiLink } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DialogBottom } from "../dialog-bottom";
import { colors } from "../theme";
import { FeedbackForm } from "./feedback-form";
import { useGetAppointmentWithCareUnitQuery } from "./get-appointment-with-care-unit.graphql";

function FeedbackDialog() {
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const navigate = useNavigate();

  const { appointmentId } = useParams();

  const {
    data: { appointment } = {},
    error,
    loading,
  } = useGetAppointmentWithCareUnitQuery({
    variables: { appointmentId: appointmentId ?? "" },
    skip: !appointmentId,
  });

  const handleCloseOrExit = () => {
    setIsDialogOpen(false);
    navigate(-1);
  };

  if (loading) {
    return null;
  }

  if (error) {
    throw new Error("Something went wrong");
  }

  return (
    <DialogBottom
      onClose={handleCloseOrExit}
      onExited={handleCloseOrExit}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          onClick={() => navigate(-1)}
          underline="none"
          color={colors.blue}
          sx={{
            cursor: "pointer",
          }}
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <FeedbackForm
        onClose={handleCloseOrExit}
        careUnitId={appointment?.careUnit?.id}
        isRatingAllowed={true}
        headingText={{
          title: t`You have got a new appointment!`,
          description: t`Help us improve by telling us what you think.`,
        }}
        success={{
          descriptionLineOne: t`We work continuously to improve the service.`,
          descriptionLineTwo: t`Thanks to you and others we can improve further.`,
        }}
      />
    </DialogBottom>
  );
}

export { FeedbackDialog };
