import { Trans } from "@lingui/macro";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Avatar, Button, Link as MuiLink, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { colors, theme } from "../theme";
import { ContactZymegoForm } from "./contact-zymego-form";

function ContactZymego() {
  const [openForm, setOpenForm] = useState(false);
  const navigate = useNavigate();

  const handleCloseOrExit = () => {
    setOpenForm(false);
    navigate(-1);
  };

  return (
    <DialogBottom onClose={handleCloseOrExit} onExited={handleCloseOrExit} open>
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 0,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <MuiLink
          onClick={() => navigate(-1)}
          underline="none"
          color={colors.blue}
          sx={{
            cursor: "pointer",
          }}
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      {openForm ? (
        <ContactZymegoForm onClose={handleCloseOrExit} />
      ) : (
        <>
          <Box
            sx={{
              paddingX: 2,
              paddingTop: 0,
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "700",
                textAlign: "center",
                color: "black",
                pb: 1,
              }}
            >
              <Trans>Zymego support</Trans>
            </Typography>
            <Box
              sx={{
                display: "inline-flex",
                borderRadius: "7px",
                background: "rgba(248,244,231,0.2)",
                backgroundColor: colors.zymegoLightTravertine,
                padding: {
                  xs: 1,
                  sm: 2,
                },
                marginX: {
                  sx: 1,
                  sm: 2,
                },
              }}
            >
              <Avatar
                sx={{
                  width: "30px",
                  height: "30px",
                  marginRight: theme.direction === "ltr" ? "8px" : "0px",
                  marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                  backgroundColor: colors.zymegoTravertine,
                  color: colors.zymegoDarkGreen,
                  fontSize: "large",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: "50%",
                  padding: {
                    xs: 1,
                    sm: 2,
                  },
                  marginTop: 1,
                }}
              >
                <PriorityHighIcon
                  sx={{
                    fontSize: 20,
                    // there is no icon matching the Figma, so use that trick instead
                    transform: "rotate(180deg)",
                  }}
                />
              </Avatar>

              <Typography
                fontSize="17px"
                color={colors.zymegoDarkGreen}
                sx={{
                  background: "rgba(0, 0, 0, 0)",
                  textAlign: theme.direction === "rtl" ? "right" : "left",
                }}
              >
                <Trans>
                  <b>Zymego is a booking system.</b>
                  <br />
                  If you want to get in touch with your clinic for medical
                  questions or questions about your visit, contact them
                  directly.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              paddingX: {
                sx: 2,
                sm: 3,
              },
              marginX: {
                sx: 1,
                sm: 2,
              },
            }}
          >
            <Button
              data-cy="contact-support-confirmation-button"
              sx={{ marginBottom: 2 }}
              fullWidth
              variant="contained"
              onClick={() => setOpenForm(true)}
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight fill="currentColor" />
                ) : (
                  <ArrowLeft fill="currentColor" />
                )
              }
            >
              <Trans>I understand</Trans>
            </Button>
          </Box>
        </>
      )}
    </DialogBottom>
  );
}

export { ContactZymego };
