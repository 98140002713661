import { Trans } from "@lingui/macro";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Avatar, Button, Link as MuiLink, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DialogBottom } from "../dialog-bottom";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";

function ConfirmLateAppointment() {
  const backgroundLocation = useBackgroundLocation();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const navigate = useNavigate();

  return (
    <DialogBottom
      onClose={() => setIsDialogOpen(false)}
      onExited={() => {
        navigate(backgroundLocation.pathname, { replace: true });
      }}
      open={isDialogOpen}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        <MuiLink
          onClick={() => navigate(-1)}
          underline="none"
          color={colors.blue}
        >
          <Trans>Close</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          paddingX: { sx: 2, sm: 3 },
          paddingBottom: { sx: 2, sm: 3 },
          borderBottom: "1px solid rgba(0 ,0 ,0 ,0.1 )",
        }}
      >
        <Typography textAlign="center" variant="h4" paddingBottom={2}>
          <Trans>Note! Fee for late changes</Trans>
        </Typography>
        <Typography textAlign="center" variant="subtitle1">
          <Trans>
            According to the clinic's rules, you will be charged for this
            appointment if you cancel/reschedule on short notice or do not
            attend.
          </Trans>
        </Typography>
      </Box>
      <Box
        sx={{
          padding: { sx: 2, sm: 2 },
          borderBottom: "1px solid rgba(0 ,0 ,0 ,0.1 )",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            borderRadius: "7px",
            background: "rgba(252,222,222,0.25)",

            padding: {
              xs: 1,
              sm: 2,
            },
            gap: "8px",
          }}
        >
          <Avatar
            sx={{
              width: "30px",
              height: "30px",
              marginRight: theme.direction === "ltr" ? "4px" : "0px",
              marginLeft: theme.direction === "rtl" ? "4px" : "0px",
              backgroundColor: colors.zymegoPowder,
              color: colors.zymegoDarkGreen,
              fontSize: "large",
            }}
          >
            <PriorityHighIcon sx={{ fontSize: 20 }} />
          </Avatar>

          <Typography
            align="left"
            fontWeight="700"
            fontSize="16px"
            color={colors.zymegoDarkGreen}
            sx={{
              background: "rgba(0, 0, 0, 0)",
            }}
          >
            <Trans>The clinic will charge you for this appointment </Trans>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: { sx: 2, sm: 3 } }}>
        <Button
          fullWidth
          endIcon={
            theme.direction === "ltr" ? (
              <ArrowRight style={{ fill: "currentColor" }} />
            ) : (
              <ArrowLeft style={{ fill: "currentcolor" }} />
            )
          }
          component={Link}
          to="../manage"
          state={{ backgroundLocation }}
          variant="contained"
          size="large"
          replace
        >
          <Trans>I understand, continue</Trans>
        </Button>
      </Box>
    </DialogBottom>
  );
}
export { ConfirmLateAppointment };
