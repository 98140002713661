import { t, Trans } from "@lingui/macro";
import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { ErrorComponent } from "../error";
import { useGetBookingTypesQuery } from "../get-booking-types.graphql";
import { useGetCareUnitsQuery } from "../get-care-units.graphql";
import { Loading } from "../loading";
import { colors } from "../theme";
import { RescheduleTimeTable } from "../time-table";
import {
  deepLinkWithProvidedBookingTypeId,
  deepLinkWithProvidedCaregiverId,
  splitByComma,
} from "../utils";
import { useGetTimeslotsLazyQuery } from "./get-timeslots.graphql";

interface FormValues {
  id: string;
}

function SelectedTimeslot() {
  const [searchParameters] = useSearchParams();
  const careUnitUrlFriendlyNames = splitByComma(
    searchParameters.get("clinicName"),
  );
  invariant(careUnitUrlFriendlyNames, "clinicName is not provided");
  const caregiverIds = searchParameters.get("caregiverId");
  invariant(caregiverIds, "caregiverId is not provided");
  const requestId = searchParameters.get("requestId");
  invariant(requestId, "request Id is not provided");
  const bookingTypeIds = searchParameters.get("bookingTypeId");
  invariant(bookingTypeIds, "booking Type Id is not provided");
  const shortlinkIdent = searchParameters.get("link");

  const earliestStartAt = searchParameters.get("earliestStartAt");
  const latestEndAt = searchParameters.get("latestEndAt");

  const parsedEarliestStartAt = useMemo(
    () => (earliestStartAt ? parseISO(earliestStartAt) : undefined),
    [earliestStartAt],
  );
  const parsedLatestEndAt = useMemo(
    () => (latestEndAt ? parseISO(latestEndAt) : undefined),
    [latestEndAt],
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [hasTimedOut, setHasTimedOut] = useState(false);
  const timeoutHandle = useRef<ReturnType<typeof setTimeout>>();

  const [
    getTimeslots,
    { loading: queryLoading, error: queryError, data: queryData },
  ] = useGetTimeslotsLazyQuery({
    fetchPolicy: "cache-and-network",
    pollInterval: 1000 * 5,
  });

  const {
    error,
    loading,
    data: { careUnits } = {},
  } = useGetCareUnitsQuery({
    variables: { careUnitUrlFriendlyNames },
  });

  const { data: { bookingTypes } = {} } = useGetBookingTypesQuery({
    variables: { bookingTypeIds: splitByComma(bookingTypeIds) ?? [] },
    skip: !bookingTypeIds,
  });

  const colorByCareUnitIdMap = useMemo(() => {
    const pastelColors = [colors.zymegoSpearMint, colors.zymegoPowder];

    const map = new Map<string, string>();
    for (const [index, careUnit] of careUnits?.entries() ?? []) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const color = pastelColors[index % pastelColors.length]!;
      map.set(careUnit.id, color);
    }
    return map;
  }, [careUnits]);

  useEffect(() => {
    getTimeslots({
      variables: {
        fetchTimeslotRequestId: requestId,
        bookingTypeIds: splitByComma(bookingTypeIds),
        careUnitIds: careUnits?.map((careUnit) => careUnit.id) ?? [],
        caregiverIds:
          caregiverIds === "all" ? undefined : splitByComma(caregiverIds),
        earliestStartAt: Number.isNaN(parsedEarliestStartAt?.getTime())
          ? undefined
          : parsedEarliestStartAt?.toISOString(),
        latestEndAt: Number.isNaN(parsedLatestEndAt?.getTime())
          ? undefined
          : parsedLatestEndAt?.toISOString(),
      },
    });

    if (timeoutHandle.current) {
      clearTimeout(timeoutHandle.current);
    }

    timeoutHandle.current = setTimeout(
      () => setHasTimedOut(true),
      5 * 60 * 1000,
    );

    return () => clearTimeout(timeoutHandle.current);
  }, [
    getTimeslots,
    requestId,
    bookingTypeIds,
    setHasTimedOut,
    timeoutHandle,
    careUnits,
    caregiverIds,
    parsedEarliestStartAt,
    parsedLatestEndAt,
  ]);

  const {
    control,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>();
  const data = watch();
  const onSubmit = ({ id }: FormValues) => {
    if (!deepLinkWithProvidedBookingTypeId(location)) {
      searchParameters.delete("bookingTypeId");
    }

    if (!deepLinkWithProvidedCaregiverId(location)) {
      searchParameters.delete("caregiverId");
    }

    searchParameters.delete("requestId");
    searchParameters.set("timeslotId", id);
    if (shortlinkIdent) searchParameters.set("link", shortlinkIdent);

    navigate({
      pathname: "../booking-information",
      search: `${searchParameters}`,
    });
  };

  // If parsed dates are invalid throw separate errors for each
  if (parsedEarliestStartAt && Number.isNaN(parsedEarliestStartAt.getTime())) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`Invalid earliest start date`}
        errorMessage={t`The provided earliest start date is invalid. Please check the url and update the date format of the earliest start date.`}
      />
    );
  }

  if (parsedLatestEndAt && Number.isNaN(parsedLatestEndAt.getTime())) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`Invalid latest end date`}
        errorMessage={t`The provided latest end date is invalid. Please check the url and update the date format of the latest end date.`}
      />
    );
  }

  if (
    loading ||
    queryLoading ||
    (queryData &&
      queryData.fetchTimeslotsRequests &&
      queryData.fetchTimeslotsRequests.some((request) =>
        ["QUEUED", "IN_PROGRESS"].includes(request.status),
      ))
  ) {
    return (
      <Loading
        logo={false}
        text={t`We are fetching available time slots from the patient record system. This may take a while.`}
      />
    );
  }

  if (
    queryError ||
    error ||
    queryData?.fetchTimeslotsRequests?.some(
      (request) => request.status === "FAILED",
    ) ||
    hasTimedOut
  ) {
    return <ErrorComponent component="new-booking-error" />;
  }

  if (!queryData?.timeslots?.length || queryData.timeslots.length <= 0) {
    return (
      <ErrorComponent
        component="new-booking-error"
        errorHeader={t`We could not find any available time slots`}
        errorMessage={t`Unfortunately we could not find any available time slots at this time. Please try again later!`}
      />
    );
  }

  const bookingType = _.uniq(bookingTypes?.map((type) => type.name));

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ flex: "auto" }}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        textAlign="center"
        flexGrow={1}
        sx={{
          paddingX: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <Box marginBottom={10}>
          <Typography fontSize="15px" fontWeight={700} marginBottom={2}>
            <Trans>
              Select your preferred time and click "Next" to continue.
            </Trans>
          </Typography>

          <Typography fontSize="15px" fontWeight={400}>
            {bookingType}
          </Typography>

          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gap={1}
            marginTop={2}
            marginBottom={3}
            justifyContent="space-between"
          >
            {careUnits &&
              careUnits.length > 1 &&
              careUnits.map((careUnit) => (
                <Box key={careUnit.id} display="flex">
                  <Box
                    width="25px"
                    height="20px"
                    bgcolor={colorByCareUnitIdMap.get(careUnit.id)}
                    borderRadius="20%"
                    boxShadow="0 0 1px rgba(0, 0, 0, 0.1)"
                  />
                  <Typography
                    sx={{ marginLeft: 2 }}
                    fontSize="14px"
                    fontWeight={500}
                  >
                    {careUnit.name}
                  </Typography>
                </Box>
              ))}
          </Box>
          <RescheduleTimeTable
            formProperties={{ control, getValues }}
            timeslots={queryData.timeslots}
            colorMap={colorByCareUnitIdMap}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              margin: "auto",
              maxWidth: "444px",
              textAlign: "center",
              background: "white",
              boxShadow: "0 -20px 20px white",
            }}
          >
            <Box
              sx={{
                paddingX: {
                  xs: 1,
                  sm: 2,
                },
              }}
            >
              {errors && (
                <Typography variant="subtitle2" color={colors.red}>
                  {errors.id?.message}
                </Typography>
              )}
              <Button
                variant="contained"
                fullWidth
                type="submit"
                endIcon={<ArrowForward />}
                disabled={!data.id}
              >
                <Trans>Next</Trans>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

export { SelectedTimeslot };
