import "./apm";
import "./mui-tel-input.css";

import { ApolloProvider } from "@apollo/client";
import { CssBaseline } from "@mui/material";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { Analytics } from "./analytics";
import { client } from "./apollo-client";
import { AppSelector } from "./app-selector";
import { ErrorComponentSimple } from "./error";
import { LocalizedApp } from "./localized-app";
import { reportWebVitals } from "./report-web-vitals";
import { ThemeComponent as Theme } from "./theme";
import { AuthenticatedUser } from "./user/authenticated-user";
import { UserProvider } from "./user/user-context";

// handle error logging
const logError = (error: Error) => {
  if (import.meta.env.DEV) {
    console.log(error);
  }
};

function fallbackRender({ error }: { error: Error }) {
  return <ErrorComponentSimple caughtError={error} />;
}

export function initApp(user: AuthenticatedUser) {
  const container = document.querySelector("#root");

  if (!container) {
    throw new Error("Root element not found");
  }

  const root = createRoot(container);

  root.render(
    <StrictMode>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <LocalizedApp>
            <HelmetProvider>
              <Theme>
                <CssBaseline />
                <ErrorBoundary
                  FallbackComponent={fallbackRender}
                  onError={logError}
                >
                  <Analytics />
                  <UserProvider user={user}>
                    <AppSelector />
                  </UserProvider>
                </ErrorBoundary>
              </Theme>
            </HelmetProvider>
          </LocalizedApp>
        </ApolloProvider>
      </BrowserRouter>
    </StrictMode>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
