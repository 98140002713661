import { Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";

// eslint-disable-next-line max-params
const transition = React.forwardRef(function Transition(
  properties: TransitionProps & {
    children: React.ReactElement;
  },
  reference: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={reference} {...properties} />;
});

function DialogBottom({
  open,
  onClose,
  onExited,
  children,
}: React.PropsWithChildren<{
  open: boolean;
  onClose?: () => void;
  onExited?: () => void;
}>) {
  return (
    <Dialog
      open={open}
      TransitionComponent={transition}
      onClose={onClose}
      TransitionProps={{ onExited }}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
      }}
      PaperProps={{
        sx: {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          m: 0,
          bottom: 0,
          width: "100%",
          maxWidth: "444px",
          padding: 0,
        },
      }}
      closeAfterTransition
    >
      {children}
    </Dialog>
  );
}

export { DialogBottom };
