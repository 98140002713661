import { getConfig } from "../runtime-config";
import {
  AuthenticatedUser,
  createUser,
  getUserStateFromLocalStorage,
} from "../user/authenticated-user";
import { userManager } from "../user-manager";

type NhsIdTokenClaims = {
  aud: string;
  auth_time: number;
  birthdate: string;
  exp: number;
  family_name: string;
  iat: number;
  id_status: string;
  identity_proofing_level: string;
  iss: string;
  jti: string;
  nhs_number: string;
  sub: string;
  surname: string;
  token_use: string;
  vot: string;
  vtm: string;
};

type NhsTokenResponseBody = {
  access_token: string;
  decoded_id_token: NhsIdTokenClaims;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
};

export async function completeNhsSignIn(): Promise<AuthenticatedUser> {
  const { AUTH_METADATA } = getConfig();
  const tokenEndpoint = AUTH_METADATA?.token_endpoint;

  if (!tokenEndpoint) {
    throw new ReferenceError(
      "Failed to complete NHS login. Token endpoint is missing.",
    );
  }

  const urlParameters = new URLSearchParams(globalThis.location.search);
  const code = urlParameters.get("code");

  if (!code) {
    throw new ReferenceError('Missing query parameter: "code"');
  }

  const query = new URLSearchParams({
    code,
    grant_type: "authorization_code",
  });

  const response = await fetch(tokenEndpoint, {
    body: query.toString(),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "POST",
  });

  const rawToken = await response.json();
  const token = rawToken as NhsTokenResponseBody & {
    id_token_claims: NhsIdTokenClaims;
  };

  const searchParameters = new URLSearchParams(globalThis.location.search);
  const stateKey = searchParameters.get("state");
  const userState = stateKey
    ? getUserStateFromLocalStorage(stateKey)
    : undefined;

  const user = createUser({
    access_token: token.access_token,
    expires_at: Math.floor(Date.now() / 1000) + token.expires_in,
    id_token: token.id_token,
    profile: token.id_token_claims,
    refresh_token: token.refresh_token,
    scope: token.scope,
    token_type: token.token_type,
    userState,
  });

  await userManager.storeUser(user);
  userManager.events.load(user);
  return user;
}
